import React, { useState } from "react";
import { useCart } from "../context/CartContext";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Notification from "../components/shared/Notification";
import LoadingSpinner from "../components/shared/LoadingSpinner";
import OrderSummaryModal from "../auth/OrderSummaryModal";

const CheckoutPage = () => {
  const { cartItems, clearCart } = useCart();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const [formData, setFormData] = useState({
    address: "",
    city: "",
    postalCode: "",
    paymentMethod: "Credit Card",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const handleOrderSubmission = () => {
    if (!currentUser) {
      showNotification(
        "Login Required",
        "You need to be logged in to place an order."
      );
      navigate("/login");
      return;
    }

    if (!formData.address || !formData.city || !formData.postalCode) {
      showNotification(
        "Incomplete Details",
        "Please fill out all the required fields."
      );
      return;
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      showNotification(
        "Order Placed",
        "Your order has been placed successfully."
      );
      clearCart();
      navigate("/orders");
    }, 2000);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const itemTotal = item.quantity * item.price + item.shippingCost;
      return total + itemTotal;
    }, 0);
  };

  if (!cartItems?.length) {
    return <p>Your cart is empty.</p>;
  }

  return (
    <div className='container my-4'>
      {loading && <LoadingSpinner />}
      <h2>Checkout</h2>
      <form className='mb-4'>
        <div className='mb-3'>
          <label htmlFor='address' className='form-label'>
            Address
          </label>
          <input
            type='text'
            id='address'
            name='address'
            className='form-control'
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='city' className='form-label'>
            City
          </label>
          <input
            type='text'
            id='city'
            name='city'
            className='form-control'
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='postalCode' className='form-label'>
            Postal Code
          </label>
          <input
            type='text'
            id='postalCode'
            name='postalCode'
            className='form-control'
            value={formData.postalCode}
            onChange={handleChange}
            required
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='paymentMethod' className='form-label'>
            Payment Method
          </label>
          <select
            id='paymentMethod'
            name='paymentMethod'
            className='form-select'
            value={formData.paymentMethod}
            onChange={handleChange}
          >
            <option value='Credit Card'>Credit Card</option>
            <option value='PayPal'>PayPal</option>
            <option value='Bank Transfer'>Bank Transfer</option>
          </select>
        </div>
      </form>

      {/* Inline Order Summary */}
      <h3>Order Summary</h3>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Item</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Shipping</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item, idx) => (
            <tr key={idx}>
              <td>{item.name}</td>
              <td>{item.quantity}</td>
              <td>${item.price.toFixed(2)}</td>
              <td>${item.shippingCost.toFixed(2)}</td>
              <td>
                ${(item.quantity * item.price + item.shippingCost).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan='4' className='text-end'>
              <strong>Total:</strong>
            </td>
            <td>
              <strong>${calculateTotal().toFixed(2)}</strong>
            </td>
          </tr>
        </tfoot>
      </table>

      {/* Action Buttons */}
      <div className='d-flex mt-4'>
        <button
          className='btn btn-secondary me-3'
          onClick={() => setIsModalOpen(true)} // Open modal for detailed review
        >
          Review Order
        </button>
        <button
          className='btn btn-success'
          onClick={handleOrderSubmission}
          disabled={loading}
        >
          Place Order
        </button>
      </div>

      {/* Order Review Modal */}
      <OrderSummaryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        cartItems={cartItems}
      />

      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={3000}
      />
    </div>
  );
};

export default CheckoutPage;
