import React from "react";
import logo from "../../assets/logo.svg";

const LoadingSpinner = () => {
  return (
    <div className='loading-spinner-container'>
      <div className='spinner-circle'>
        {/* Circle is the only spinning element */}
      </div>
      <div className='spinner-content'>
        <img src={logo} alt='Logo' className='spinner-logo' />
      </div>
    </div>
  );
};

export default LoadingSpinner;
