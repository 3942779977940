import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { db } from "../firebase/config";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Notification from "../components/shared/Notification";
import LoadingSpinner from "../components/shared/LoadingSpinner";
import {
  PrimaryButton,
  SecondaryButton,
  DangerButton,
} from "../components/shared/StyledComponents";
import { useNavigate } from "react-router-dom";

const AdminAddProducts = () => {
  const navigate = useNavigate();

  const [suppliers, setSuppliers] = useState([]); // To store all suppliers
  const [selectedSupplier, setSelectedSupplier] = useState(null); // Selected supplier object
  const [supplierId, setSupplierId] = useState("");
  const [supplierNumber, setSupplierNumber] = useState("");

  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [description, setDescription] = useState("");
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([null]);
  const [priceRanges, setPriceRanges] = useState([
    {
      minQty: "",
      maxQty: "",
      price: "",
      locations: [{ location: "", locationPrice: "" }],
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [mainLocation, setMainLocation] = useState(null);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const supplierCollection = collection(db, "users");
        const supplierSnapshot = await getDocs(supplierCollection);
        const supplierList = supplierSnapshot.docs
          .filter((doc) => doc.data().role === "supplier") // Adjust the role condition
          .map((doc) => ({
            id: doc.id,
            name: doc.data().name || "Unnamed Supplier",
            contact: doc.data().contact || "N/A",
          }));
        setSuppliers(supplierList);
      } catch (error) {
        console.error("Error fetching suppliers: ", error);
      }
    };

    fetchSuppliers();
  }, []);

  const handleSupplierChange = (e) => {
    const selectedName = e.target.value;
    const supplier = suppliers.find(
      (supplier) => supplier.name === selectedName
    );
    setSelectedSupplier(supplier);
    setSupplierId(supplier?.id || "N/A");
    setSupplierNumber(supplier?.contact || "N/A");
  };

  const defaultSizeOptions = [
    { value: "XS", label: "XS" },
    { value: "S", label: "S" },
    { value: "M", label: "M" },
    { value: "L", label: "L" },
    { value: "XL", label: "XL" },
    { value: "XXL", label: "XXL" },
  ];

  const defaultColorOptions = [
    { value: "Red", label: "Red" },
    { value: "Blue", label: "Blue" },
    { value: "Green", label: "Green" },
    { value: "Yellow", label: "Yellow" },
    { value: "Black", label: "Black" },
    { value: "White", label: "White" },
    { value: "Pink", label: "Pink" },
    { value: "Purple", label: "Purple" },
    { value: "Orange", label: "Orange" },
    { value: "Gray", label: "Gray" },
  ];

  const defaultQtyOptions = Array.from({ length: 100 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  })).concat({ value: "Unlimited", label: "Unlimited" });

  const defaultLocationOptions = [
    "Riyadh",
    "Jeddah",
    "Mecca",
    "Medina",
    "Dammam",
    "Khobar",
    "Tabuk",
    "Abha",
    "Khamis Mushait",
    "Buraidah",
    "Najran",
    "Al Hufuf",
    "Yanbu",
    "Al Jubail",
    "Al Khafji",
    "Arar",
    "Sakaka",
    "Hafar Al-Batin",
    "Qatif",
    "Al Bahah",
    "Jizan",
    "Al Majma'ah",
    "Al Zulfi",
    "Unaizah",
    "Rabigh",
    "Ras Tanura",
    "Safwa",
    "Turubah",
    "Turaif",
    "Wadi ad-Dawasir",
  ].map((city) => ({ value: city, label: city }));

  const [locationOptions, setLocationOptions] = useState(
    defaultLocationOptions
  );

  const [sizeOptions, setSizeOptions] = useState(defaultSizeOptions);
  const [colorOptions, setColorOptions] = useState(defaultColorOptions);
  const [qtyOptions, setQtyOptions] = useState(defaultQtyOptions);

  const handleCreateQtyOption = (newOption) => {
    if (!qtyOptions.some((opt) => opt.value === newOption)) {
      const newOptionObj = { value: newOption, label: newOption };
      setQtyOptions((prevOptions) => [...prevOptions, newOptionObj]);
      return newOptionObj;
    }
    return null;
  };

  const [subCategoryOptions, setSubCategoryOptions] = useState({
    Construction: ["Sand", "Cement", "Wood", "Bricks"],
    "Plastic & Papers": [
      "Paper Cups",
      "Plastic Cups",
      "Plastic Packaging",
      "White Papers",
    ],
    Equipment: [
      "Drilling Equipment",
      "Transportation Equipment",
      "Coating Equipment",
      "Construction Equipment",
    ],
    "Saudi Manufactured Products": [
      "Subcategory 1",
      "Subcategory 2",
      "Subcategory 3",
      "Subcategory 4",
    ],
  });

  const handleAddLocation = (priceRangeIndex) => {
    const updatedRanges = [...priceRanges];
    updatedRanges[priceRangeIndex].locations.push({
      location: "",
      locationPrice: "",
    });
    setPriceRanges(updatedRanges);
  };

  const handleLocationChange = (
    priceRangeIndex,
    locationIndex,
    field,
    value
  ) => {
    const updatedRanges = [...priceRanges];
    updatedRanges[priceRangeIndex].locations[locationIndex][field] = value;
    setPriceRanges(updatedRanges);
  };

  const handleRemoveLocation = (priceRangeIndex, locationIndex) => {
    const updatedRanges = [...priceRanges];
    updatedRanges[priceRangeIndex].locations.splice(locationIndex, 1);
    setPriceRanges(updatedRanges);
  };

  const handleAddAdditionalImage = () => {
    setAdditionalImages([...additionalImages, null]);
  };

  const handleAdditionalImageChange = (index, file) => {
    const updatedImages = [...additionalImages];
    updatedImages[index] = file;
    setAdditionalImages(updatedImages);
  };

  const handleRemoveAdditionalImage = (index) => {
    const updatedImages = [...additionalImages];
    updatedImages.splice(index, 1);
    setAdditionalImages(updatedImages);
  };

  const handleAddPriceRange = () => {
    setPriceRanges([
      ...priceRanges,
      {
        minQty: "",
        maxQty: "",
        price: "",
        locations: [{ location: "", locationPrice: "" }], // Ensure locations array is initialized
      },
    ]);
  };

  const handlePriceRangeChange = (index, field, value) => {
    const updatedRanges = [...priceRanges];
    updatedRanges[index][field] = value;
    setPriceRanges(updatedRanges);
  };

  const handleRemovePriceRange = (index) => {
    const updatedRanges = [...priceRanges];
    updatedRanges.splice(index, 1);
    setPriceRanges(updatedRanges);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (
      !productName ||
      !category ||
      !subCategory ||
      !description ||
      sizes.length === 0 ||
      colors.length === 0 ||
      !mainImage ||
      !mainLocation || // Add this line
      priceRanges.length === 0
    ) {
      showNotification(
        "Validation Error",
        "Please fill in all required fields before submitting."
      );
      return;
    }

    setLoading(true); // Start loading spinner
    setMessage({ type: "", text: "" });

    try {
      const storage = getStorage();

      // Upload main image
      const mainImageRef = ref(storage, `images/${mainImage.name}`);
      await uploadBytes(mainImageRef, mainImage);
      const mainImageUrl = await getDownloadURL(mainImageRef);

      // Upload additional images
      const additionalImageUrls = [];
      for (const file of additionalImages) {
        if (file) {
          const imageRef = ref(storage, `images/${file.name}`);
          await uploadBytes(imageRef, file);
          const imageUrl = await getDownloadURL(imageRef);
          additionalImageUrls.push(imageUrl);
        }
      }

      // Save to Firestore
      await addDoc(collection(db, "products"), {
        productName,
        category,
        subCategory,
        description,
        sizes: sizes.map((size) => size.value),
        colors: colors.map((color) => color.value),
        mainImageUrl,
        additionalImageUrls,
        mainLocation: mainLocation?.value || null,
        priceRanges: priceRanges.map((range) => ({
          ...range,
          locations: range.locations.map((loc) => ({
            location: loc.location,
            locationPrice: parseFloat(loc.locationPrice || 0),
          })),
        })),
        createdAt: new Date(),
        supplierId: supplierId || "N/A",
        supplierName: selectedSupplier?.name || "N/A",
        supplierNumber: supplierNumber || "N/A",
      });

      showNotification(
        "Success",
        "Your product has been uploaded successfully."
      );

      // Reset the form
      setProductName("");
      setCategory("");
      setSubCategory("");
      setDescription("");
      setSizes([]);
      setColors([]);
      setMainImage(null);
      setAdditionalImages([]);
      setPriceRanges([
        {
          minQty: "",
          maxQty: "",
          price: "",
          locations: [{ location: "", locationPrice: "" }],
        },
      ]);

      // Redirect to admin-page
      navigate("/admin-dashboard");
    } catch (error) {
      console.error("Error adding product: ", error);
      showNotification(
        "Submission Error",
        "Failed to upload the product. Please try again later."
      );
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleCreateOption = (newOption, currentOptions, setOptions) => {
    const isDuplicate = currentOptions.some(
      (option) => option.value.toLowerCase() === newOption.toLowerCase()
    );
    if (isDuplicate) {
      setMessage({
        type: "warning",
        text: `The option "${newOption}" already exists.`,
      });
      return;
    }
    const newOptionObj = { value: newOption, label: newOption };
    setOptions([...currentOptions, newOptionObj]);
    return newOptionObj;
  };

  const handleCreateLocationOption = (newLocation) => {
    const newOption = { value: newLocation, label: newLocation };
    setLocationOptions([...locationOptions, newOption]);
    return newOption;
  };

  const getSubCategoryOptions = () =>
    (subCategoryOptions[category] || []).map((sub) => ({
      value: sub,
      label: sub,
    }));

  return (
    <div className='container mt-3'>
      <h2 className='mb-3'>Upload Product</h2>
      {loading && <LoadingSpinner />}
      {message.text && (
        <div className={`alert alert-${message.type} mt-2`} role='alert'>
          {message.text}
        </div>
      )}

      <div className='container mt-4'>
        <div className='row border p-3'>
          <div className='col-md-4'>
            <h6>Supplier Name</h6>
            <select
              className='form-control'
              value={selectedSupplier?.name || ""}
              onChange={handleSupplierChange}
            >
              <option value='' disabled>
                Select a Supplier
              </option>
              {suppliers.map((supplier) => (
                <option key={supplier.id} value={supplier.name}>
                  {supplier.name}
                </option>
              ))}
            </select>
          </div>
          <div className='col-md-4'>
            <h6>Supplier ID</h6>
            <p>{supplierId || "N/A"}</p>
          </div>
          <div className='col-md-4'>
            <h6>Supplier Number</h6>
            <p>{supplierNumber || "N/A"}</p>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className='mt-3'>
        <div className='row g-2'>
          <div className='col-md-3'>
            <label htmlFor='productName' className='form-label'>
              Product Name
            </label>
            <input
              type='text'
              className='form-control'
              id='productName'
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
          </div>

          <div className='col-md-3'>
            <label htmlFor='category' className='form-label'>
              Category
            </label>
            <select
              className='form-control'
              id='category'
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
                setSubCategory("");
              }}
              required
            >
              <option value=''>Select a Category</option>
              {Object.keys(subCategoryOptions).map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>

          <div className='col-md-3'>
            <label htmlFor='subCategory' className='form-label'>
              Sub Category
            </label>
            <CreatableSelect
              options={getSubCategoryOptions()}
              value={
                subCategory ? { value: subCategory, label: subCategory } : null
              }
              onChange={(selected) => setSubCategory(selected?.value || "")}
              onCreateOption={(newSubCategory) => {
                const newSubCategoryObj = handleCreateOption(
                  newSubCategory,
                  getSubCategoryOptions(),
                  (updatedSubCategories) => {
                    setSubCategoryOptions({
                      ...subCategoryOptions,
                      [category]: updatedSubCategories.map(
                        (option) => option.value
                      ),
                    });
                  }
                );
                if (newSubCategoryObj) setSubCategory(newSubCategoryObj.value);
              }}
              isDisabled={!category}
              placeholder='Select or create a subcategory'
            />
          </div>

          <div className='col-md-3'>
            <label htmlFor='description' className='form-label'>
              Description
            </label>
            <input
              type='text'
              className='form-control'
              id='description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className='row g-2 mt-2'>
            <div className='col-md-4'>
              <label htmlFor='mainLocation' className='form-label'>
                Main Location
              </label>
              <CreatableSelect
                options={locationOptions}
                value={mainLocation}
                onChange={(selected) => setMainLocation(selected)}
                onCreateOption={(newLocation) => {
                  const newOption = handleCreateOption(
                    newLocation,
                    locationOptions,
                    setLocationOptions
                  );
                  if (newOption) setMainLocation(newOption);
                }}
                placeholder='Select or create a location'
              />
            </div>

            <div className='col-md-4'>
              <label htmlFor='sizes' className='form-label'>
                Sizes
              </label>
              <CreatableSelect
                options={sizeOptions}
                isMulti
                value={sizes}
                onChange={(selected) => setSizes(selected)}
                onCreateOption={(newSize) => {
                  const newSizeObj = handleCreateOption(
                    newSize,
                    sizeOptions,
                    setSizeOptions
                  );
                  if (newSizeObj) setSizes([...sizes, newSizeObj]);
                }}
                classNamePrefix='select'
                placeholder='Select or create a Size'
              />
            </div>

            <div className='col-md-4'>
              <label htmlFor='colors' className='form-label'>
                Colors
              </label>
              <CreatableSelect
                options={colorOptions}
                isMulti
                value={colors}
                onChange={(selected) => setColors(selected)}
                onCreateOption={(newColor) => {
                  const newColorObj = handleCreateOption(
                    newColor,
                    colorOptions,
                    setColorOptions
                  );
                  if (newColorObj) setColors([...colors, newColorObj]);
                }}
                classNamePrefix='select'
                placeholder='Select or create a Color'
              />
            </div>
          </div>
          <div className='row g-2 mt-2 align-items-start'>
            {/* Main Image */}
            <div className='col-md-6'>
              <label htmlFor='mainImage' className='form-label'>
                Main Image
              </label>
              <input
                type='file'
                className='form-control'
                id='mainImage'
                accept='image/*'
                onChange={(e) => setMainImage(e.target.files[0])}
                required
              />
            </div>

            {/* Additional Images */}
            <div className='col-md-6'>
              <label className='form-label'>Additional Images</label>
              {additionalImages.map((_, index) => (
                <div key={index} className='d-flex mb-1 align-items-center'>
                  <input
                    type='file'
                    className='form-control me-2'
                    accept='image/*'
                    onChange={(e) =>
                      handleAdditionalImageChange(index, e.target.files[0])
                    }
                  />
                  <DangerButton
                    type='button'
                    onClick={() => handleRemoveAdditionalImage(index)}
                  >
                    Remove
                  </DangerButton>
                </div>
              ))}
              <SecondaryButton
                type='button'
                className='btn btn-secondary btn-sm mt-2'
                onClick={handleAddAdditionalImage}
              >
                Add Another Image
              </SecondaryButton>
            </div>
          </div>

          <div className='col-md-12 mt-3'>
            {priceRanges.map((range, index) => (
              <div key={index} className='mb-4'>
                <div className='row g-3 align-items-start'>
                  {/* Price Ranges Section */}
                  <div className='col-md-6'>
                    <h6 className='fw-bold'>Price Range</h6>
                    <div className='row g-2'>
                      <div className='col-md-4'>
                        <CreatableSelect
                          options={qtyOptions}
                          value={
                            range.minQty
                              ? { value: range.minQty, label: range.minQty }
                              : null
                          }
                          onChange={(selected) =>
                            handlePriceRangeChange(
                              index,
                              "minQty",
                              selected?.value || ""
                            )
                          }
                          onCreateOption={(newQty) => {
                            const newOption = handleCreateQtyOption(newQty);
                            if (newOption)
                              handlePriceRangeChange(
                                index,
                                "minQty",
                                newOption.value
                              );
                          }}
                          placeholder='Min Qty'
                        />
                      </div>
                      <div className='col-md-4'>
                        <CreatableSelect
                          options={qtyOptions}
                          value={
                            range.maxQty
                              ? { value: range.maxQty, label: range.maxQty }
                              : null
                          }
                          onChange={(selected) =>
                            handlePriceRangeChange(
                              index,
                              "maxQty",
                              selected?.value || ""
                            )
                          }
                          onCreateOption={(newQty) => {
                            const newOption = handleCreateQtyOption(newQty);
                            if (newOption)
                              handlePriceRangeChange(
                                index,
                                "maxQty",
                                newOption.value
                              );
                          }}
                          placeholder='Max Qty'
                        />
                      </div>

                      <div className='col-md-4'>
                        <input
                          type='number'
                          className='form-control'
                          placeholder='Price'
                          value={range.price}
                          onChange={(e) =>
                            handlePriceRangeChange(
                              index,
                              "price",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                    </div>
                    <DangerButton
                      type='button'
                      className='btn btn-danger btn-sm mt-2'
                      onClick={() => handleRemovePriceRange(index)}
                    >
                      Remove Price Range
                    </DangerButton>
                  </div>

                  {/* Locations Section */}
                  <div className='col-md-6'>
                    <h6 className='fw-bold'>Delivery Locations</h6>
                    {range.locations?.map((loc, locIndex) => (
                      <div
                        key={locIndex}
                        className='row g-2 align-items-center mb-2'
                      >
                        <div className='col-md-6'>
                          <CreatableSelect
                            options={locationOptions}
                            value={
                              loc.location
                                ? { value: loc.location, label: loc.location }
                                : null
                            }
                            onChange={(selected) =>
                              handleLocationChange(
                                index,
                                locIndex,
                                "location",
                                selected?.value || ""
                              )
                            }
                            onCreateOption={(newOption) => {
                              const newLoc =
                                handleCreateLocationOption(newOption);
                              handleLocationChange(
                                index,
                                locIndex,
                                "location",
                                newLoc.value
                              );
                            }}
                            placeholder='Select or create a location'
                          />
                        </div>
                        <div className='col-md-5'>
                          <input
                            type='number'
                            className='form-control'
                            placeholder='Delivery Price'
                            value={loc.locationPrice || ""}
                            onChange={(e) =>
                              handleLocationChange(
                                index,
                                locIndex,
                                "locationPrice",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className='col-md-1'>
                          <DangerButton
                            type='button'
                            className='btn btn-danger btn-sm'
                            onClick={() =>
                              handleRemoveLocation(index, locIndex)
                            }
                          >
                            Remove
                          </DangerButton>
                        </div>
                      </div>
                    ))}
                    <SecondaryButton
                      type='button'
                      className='btn btn-secondary btn-sm'
                      onClick={() => handleAddLocation(index)}
                    >
                      Add Location
                    </SecondaryButton>
                  </div>
                </div>
              </div>
            ))}
            <SecondaryButton
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={handleAddPriceRange}
            >
              Add Price Range
            </SecondaryButton>
          </div>
        </div>

        <div className='mt-5'>
          <PrimaryButton
            type='submit'
            className='btn btn-primary btn-md'
            disabled={loading} // Disable button during loading
          >
            {loading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>{" "}
                Uploading...
              </>
            ) : (
              "Upload Product"
            )}
          </PrimaryButton>
        </div>
      </form>
      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)} // Close handler
        title={notificationContent.title}
        message={notificationContent.message}
        duration={5000} // Auto-close after 3 seconds
      />
    </div>
  );
};

export default AdminAddProducts;
