import React, { useState, useEffect } from "react";
import { BsFillShieldLockFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  onAuthStateChanged,
} from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../firebase/config";
import { collection, query, where, getDocs } from "firebase/firestore";
import Notification from "../components/shared/Notification";

const BuyerSupplierLogin = () => {
  const [otp, setOtp] = useState("");
  const [countryCode, setCountryCode] = useState("+966"); // Default country code
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const navigate = useNavigate();

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });
  // const [loading, setLoading] = useState(false); // Loading state for the spinner

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;

    // Check if input contains non-numeric characters or exceeds 10 digits
    if (/^\d{0,10}$/.test(value)) {
      if (value.startsWith("0")) {
        showNotification("Phone number cannot start with 0");
      } else {
        setPhone(value);
        // Enable the button if the input is between 9 and 10 digits
        setIsButtonDisabled(value.length < 9);
      }
    } else {
      showNotification(
        "Please Enter Only Numbers and Maximum of 10 Digits Only"
      );
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          const role = userData.role || "buyer"; // Default role to buyer if missing

          // Save user details to localStorage
          localStorage.setItem("userId", user.uid);
          localStorage.setItem("userName", userData.name || "Anonymous");
          localStorage.setItem("userRole", role);

          const redirectRoute = role === "buyer" ? "/" : "/supplier-dashboard";
          navigate(redirectRoute);
        } else {
          navigate("/register");
        }
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [navigate]);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {
            console.log("Recaptcha expired, please verify again.");
          },
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;
    const fullPhoneNumber = `${countryCode}${phone}`.trim();

    signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
        setLoading(false);
      });
  }

  async function onOTPVerify() {
    try {
      setLoading(true);
      const result = await window.confirmationResult.confirm(otp);
      const fullPhoneNumber = `${countryCode}${phone}`.trim();
      const user = result.user;

      // Save the phone number to localStorage
      localStorage.setItem("userPhone", fullPhoneNumber);

      const usersRef = collection(db, "users");
      let q = query(usersRef, where("uid", "==", user.uid));
      let querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        q = query(usersRef, where("phone", "==", fullPhoneNumber));
        querySnapshot = await getDocs(q);
      }

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        const role = userData.role || "buyer";
        const redirectRoute = role === "buyer" ? "/" : "/supplier-dashboard";

        // Save user details to localStorage
        localStorage.setItem("userId", user.uid);
        localStorage.setItem("userName", userData.name || "Anonymous");
        localStorage.setItem("userRole", role);

        toast.success("Login successful!");
        setLoading(false);
        navigate(redirectRoute);
      } else {
        setLoading(false);
        navigate("/register");
      }
    } catch (err) {
      console.error("OTP verification or Firestore query failed:", err);
      setLoading(false);
      toast.error("Invalid OTP or Firestore query issue. Please try again.");
    }
  }

  return (
    <div
      className='container-fluid d-flex flex-column align-items-center justify-content-center'
      style={{ minHeight: "100vh", backgroundColor: "#f7f7f7" }}
    >
      <Toaster toastOptions={{ duration: 4000 }} />
      <div id='recaptcha-container'></div>

      <div className='text-center mb-4 d-flex flex-column align-items-center'>
        <img
          src='./logo-marsos.svg'
          alt='Logo'
          style={{ width: "80px", marginBottom: "10px" }}
        />
        <h2 style={{ color: "#2d6a4f", fontWeight: "bold" }}>
          Login or Register
        </h2>
      </div>

      <form
        onSubmit={(e) => e.preventDefault()}
        className='p-4 border rounded shadow-sm bg-white'
        style={{
          width: "100%",
          maxWidth: "350px",
          borderRadius: "8px",
          margin: "0 auto",
        }}
      >
        {showOTP ? (
          <>
            <h5 className='text-center mb-4' style={{ color: "#2d6a4f" }}>
              Enter OTP
            </h5>
            <div className='d-flex justify-content-center mb-4'>
              <BsFillShieldLockFill size={40} color='#2d6a4f' />
            </div>
            <OtpInput
              value={otp}
              onChange={setOtp}
              OTPLength={6}
              otpType='number'
              disabled={false}
              autoFocus
              className='opt-container mb-3'
            />
            <button onClick={onOTPVerify} className='btn btn-success w-100'>
              {loading && <CgSpinner size={20} className='mt-1 animate-spin' />}
              Verify OTP
            </button>
          </>
        ) : (
          <>
            <h5 className='text-center mb-4' style={{ color: "#2d6a4f" }}>
              Verify your account
            </h5>
            <div className='input-group mb-3'>
              <select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className='form-select'
                style={{ maxWidth: "90px" }}
              >
                {/* GCC Country Codes */}
                <option value='+966'>+966 (Saudi Arabia)</option>
                <option value='+971'>+971 (United Arab Emirates)</option>
                <option value='+973'>+973 (Bahrain)</option>
                <option value='+965'>+965 (Kuwait)</option>
                <option value='+968'>+968 (Oman)</option>
                <option value='+974'>+974 (Qatar)</option>

                {/* Other Arab Countries
                <option value='+20'>+20 (Egypt)</option>
                <option value='+212'>+212 (Morocco)</option>
                <option value='+213'>+213 (Algeria)</option>
                <option value='+216'>+216 (Tunisia)</option>
                <option value='+218'>+218 (Libya)</option>
                <option value='+964'>+964 (Iraq)</option>
                <option value='+962'>+962 (Jordan)</option>
                <option value='+961'>+961 (Lebanon)</option>
                <option value='+970'>+970 (Palestine)</option>
                <option value='+249'>+249 (Sudan)</option>
                <option value='+963'>+963 (Syria)</option>
                <option value='+252'>+252 (Somalia)</option>
                <option value='+222'>+222 (Mauritania)</option>
                <option value='+269'>+269 (Comoros)</option>
                <option value='+221'>+221 (Senegal - Arabized areas)</option> */}

                {/* Philippines (existing option) */}
                <option value='+63'>+63</option>
              </select>

              <input
                type='tel'
                placeholder='Enter your phone number'
                value={phone}
                onChange={handlePhoneChange}
                className='form-control custom-placeholder'
                required
              />
            </div>
            {!showOTP && (
              <div className='form-check mb-3'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='agreementCheckboxSignup'
                  required
                />
                <label
                  className='form-check-label'
                  htmlFor='agreementCheckboxSignup'
                  style={{ fontSize: "0.9rem", color: "#2d6a4f" }}
                >
                  I agree to the{" "}
                  <a
                    href='/updated-terms-and-conditions'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Terms & Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href='/updated-privacy-policy'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
            )}
            <button
              onClick={onSignup}
              className='btn btn-success w-100'
              disabled={isButtonDisabled}
            >
              {loading && <CgSpinner size={20} className='mt-1 animate-spin' />}
              Send code via SMS
            </button>
          </>
        )}
      </form>
      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={1000} // Auto-close after 3 seconds
      />
    </div>
  );
};

export default BuyerSupplierLogin;
