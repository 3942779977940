import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { db, auth } from "../../firebase/config";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const EditProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("User not authenticated.");
          return;
        }

        const productsRef = collection(db, "products");
        const productsQuery = query(
          productsRef,
          where("supplierId", "==", user.uid)
        );
        const productsSnapshot = await getDocs(productsQuery);

        const productList = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(productList);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (productId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, "products", productId));
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== productId)
      );
      alert("Product deleted successfully.");
    } catch (error) {
      console.error("Error deleting product:", error);
      alert("Failed to delete the product.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!products.length) {
    return <div>No products found.</div>;
  }

  return (
    <Container className='my-5'>
      <Row>
        <Col>
          <h2 className='mb-4'>Manage Products</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>Stock</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={product.id}>
                  <td>{index + 1}</td>
                  <td>{product.productName || "Unnamed Product"}</td>
                  <td>${product.price || "N/A"}</td>
                  <td>{product.stock || 0}</td>
                  <td>
                    <Button
                      variant='primary'
                      size='sm'
                      className='me-2'
                      onClick={() => navigate(`/edit-product/${product.id}`)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant='danger'
                      size='sm'
                      onClick={() => handleDelete(product.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Button variant='success' onClick={() => navigate("/add-product")}>
        Add New Product
      </Button>
    </Container>
  );
};

export default EditProducts;
