import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AdminLogin from "./auth/AdminLogin";
import BuyerSupplierLogin from "./auth/BuyerSupplierLogin";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import SupplierDashboard from "./components/dashboard/SupplierDashboard";
import BuyerDashboard from "./components/dashboard/BuyerDashboard";
import PrivateRoute from "./utils/PrivateRoute";
import Layout from "./components/shared/Layout";
import Register from "./auth/Register";
import SupplierRegistration from "./auth/SupplierRegistration";
import BuyerRegistration from "./auth/BuyerRegistration";
import AddProductForm from "./components/products/AddProductForm";
import ProductList from "./components/products/ProductList";
import Products from "./pages/Products";
import ProductDetails from "./components/shared/ProductDetails";
import CartPage from "./auth/CartPage";
import ChatPage from "./auth/ChatPage";
import EditProducts from "./components/products/EditProducts";
import EditSingleProduct from "./components/products/EditSingleProduct";
import CheckoutPage from "./auth/CheckOutPage";
import AdminAddProducts from "./auth/AdminAddProducts";
import AdminEditProducts from "./auth/AdminEditProducts";
import Dashboard from "./components/dashboard/AdminDashBoard2";
import TermsAndConditions from "./components/shared/TermsAndConditions";
import PrivacyPolicy from "./components/shared/PrivacyPolicy";
import UpdatedPrivacyPolicy from "./pages/UpdatedPrivacyPolicy";
import UpdatedTermsAndConditions from "./pages/UpdatedTermsAndConditions";
import SupplierProductsPage from "./pages/SupplierProductsPage";
import Categories from "./pages/Categories";
import CategoryDetails from "./pages/CategoryDetails";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path='/terms-and-conditions'
        element={
          <Layout>
            <TermsAndConditions />
          </Layout>
        }
      />
      <Route
        path='/updated-terms-and-conditions'
        element={
          <Layout>
            <UpdatedTermsAndConditions />
          </Layout>
        }
      />
      <Route
        path='/privacy-policy'
        element={
          <Layout>
            <PrivacyPolicy />
          </Layout>
        }
      />
      <Route
        path='/updated-privacy-policy'
        element={
          <Layout>
            <UpdatedPrivacyPolicy />
          </Layout>
        }
      />
      <Route
        path='/user-login'
        element={
          <Layout>
            <BuyerSupplierLogin />
          </Layout>
        }
      />

      <Route
        path='/register'
        element={
          <Layout>
            <Register />
          </Layout>
        }
      />
      <Route
        path='/supplier'
        element={
          <Layout>
            <SupplierRegistration />
          </Layout>
        }
      />
      <Route
        path='/buyer'
        element={
          <Layout>
            <BuyerRegistration />
          </Layout>
        }
      />
      <Route
        path='/products'
        element={
          <Layout>
            <Products />
          </Layout>
        }
      />
      <Route
        path='/product/:id'
        element={
          <Layout>
            <ProductDetails />
          </Layout>
        }
      />
      <Route
        path='/categories'
        element={
          <Layout>
            <Categories />
          </Layout>
        }
      />
      <Route
        path='/categories/:id'
        element={
          <Layout>
            <CategoryDetails />
          </Layout>
        }
      />
      <Route
        path='/supplier/:supplierId'
        element={
          <Layout>
            <SupplierProductsPage />
          </Layout>
        }
      />
      <Route
        path='/admin-login'
        element={
          <Layout>
            <AdminLogin />
          </Layout>
        }
      />
      <Route
        path='/admin-add-products'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <AdminAddProducts />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/dashboard'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <Dashboard />
            </PrivateRoute>
          </Layout>
        }
      />

      <Route
        path='/admin-edit-products/:productId'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <AdminEditProducts />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/cart'
        element={
          <Layout>
            <PrivateRoute role='buyer'>
              <CartPage />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/admin-dashboard'
        element={
          <Layout>
            <PrivateRoute role='admin'>
              <AdminDashboard />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/supplier-dashboard'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <SupplierDashboard />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/product-list'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <ProductList />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/edit-products'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <EditProducts />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/edit-product/:productId'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <EditSingleProduct />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/chat/:chatId'
        element={
          <Layout>
            <PrivateRoute roles={["supplier", "buyer"]}>
              <ChatPage />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/checkout'
        element={
          <Layout>
            <PrivateRoute role='buyer'>
              <CheckoutPage />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/add-product'
        element={
          <Layout>
            <PrivateRoute role='supplier'>
              <AddProductForm />
            </PrivateRoute>
          </Layout>
        }
      />
      <Route
        path='/buyer-dashboard'
        element={
          <Layout>
            <PrivateRoute role='buyer'>
              <BuyerDashboard />
            </PrivateRoute>
          </Layout>
        }
      />
    </Routes>
  );
};
