import React, { useEffect, useState, useRef } from "react";
import { Send, Image, FileText, MapPin } from "react-feather";
import { useParams } from "react-router-dom";
import {
  doc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  getDocs,
  collection,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../firebase/config";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const ChatPage = () => {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [productDetails, setProductDetails] = useState({});
  const [supplierInfo, setSupplierInfo] = useState({});
  const [cartDetails, setCartDetails] = useState({});
  const [chatHeads, setChatHeads] = useState([]);
  const [userRole, setUserRole] = useState(""); // Role: "buyer" or "supplier"
  const [buyerInfo, setBuyerInfo] = useState(""); // To fetch buyer name for suppliers
  const [loadingRole, setLoadingRole] = useState(true); // To handle loading state for role
  const [editingField, setEditingField] = useState(null); // Track the field being edited
  const storage = getStorage();

  // Ref for auto-scrolling
  const messagesEndRef = useRef(null);

  // Scroll to the bottom of messages
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Fetch user role from Firestore
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
          const userId = currentUser.uid;
          const userDoc = await getDoc(doc(db, "users", userId));

          if (userDoc.exists()) {
            setUserRole(userDoc.data().role || "buyer"); // Default to "buyer"
          } else {
            console.error("User document not found");
            setUserRole("buyer"); // Fallback to buyer
          }
        } else {
          console.error("No authenticated user");
          setUserRole("buyer"); // Fallback for unauthenticated state
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
        setUserRole("buyer"); // Fallback to buyer in case of error
      } finally {
        setLoadingRole(false); // Stop loading
      }
    };

    fetchUserRole();
  }, []);

  // Fetch chat details and messages
  useEffect(() => {
    const chatRef = doc(db, "chats", chatId);

    const unsubscribe = onSnapshot(chatRef, (snapshot) => {
      if (snapshot.exists()) {
        const chatData = snapshot.data();
        setMessages(chatData.messages || []);
        setProductDetails({
          name: chatData.productName,
          price: chatData.productPrice,
          minOrder: chatData.minOrder,
        });
        setSupplierInfo({
          name: chatData.supplierName || "Unknown Supplier",
        });
        setCartDetails(chatData.cartDetails || {});
        setBuyerInfo(chatData.buyerName || "Unknown Buyer");
      }
    });

    return () => unsubscribe();
  }, [chatId]);

  // Scroll to bottom when messages update
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Fetch all chats for the sidebar
  useEffect(() => {
    const fetchChats = async () => {
      try {
        const auth = getAuth();
        const currentUser = auth.currentUser;

        if (currentUser) {
          const userId = currentUser.uid;
          const chatCollection = collection(db, "chats");
          const chatDocs = await getDocs(chatCollection);

          // Filter chats based on the user's role and their ID
          const filteredChats = chatDocs.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((chat) =>
              userRole === "supplier"
                ? chat.supplierId === userId
                : chat.buyerId === userId
            );

          setChatHeads(filteredChats);
        } else {
          console.error("No authenticated user");
        }
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchChats();
  }, [userRole]);

  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    const chatRef = doc(db, "chats", chatId);
    await updateDoc(chatRef, {
      messages: arrayUnion({
        sender: userRole, // Dynamic sender role
        content: newMessage,
        timestamp: new Date().toISOString(),
      }),
    });
    setNewMessage("");
  };

  const handleFileUpload = async (file) => {
    if (!file) return;

    try {
      if (file.size > 10 * 1024 * 1024) {
        alert("File size exceeds 10MB!");
        return;
      }

      const fileRef = ref(storage, `chat-attachments/${chatId}/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileURL = await getDownloadURL(fileRef);

      const chatRef = doc(db, "chats", chatId);
      await updateDoc(chatRef, {
        messages: arrayUnion({
          sender: userRole,
          content: "File uploaded",
          fileURL,
          timestamp: new Date().toISOString(),
        }),
      });
      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
    }
  };

  const handleEdit = (field) => {
    setEditingField(field);
  };

  const handleSave = async (field) => {
    try {
      const chatRef = doc(db, "chats", chatId);
      await updateDoc(chatRef, {
        cartDetails, // Update the cart details with the modified state
      });
      setEditingField(null); // Stop editing mode
      alert(`${field} updated successfully!`);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      alert(`Failed to update ${field}. Please try again.`);
    }
  };

  if (loadingRole) {
    return <div>Loading...</div>;
  }

  return (
    <div className='chat-page'>
      <div className='sidebar'>
        <h3>Chats</h3>
        {chatHeads.map((chat) => (
          <div
            key={chat.id}
            className={`chat-head ${chat.id === chatId ? "active" : ""}`}
            onClick={() => (window.location.href = `/chat/${chat.id}`)}
          >
            <p>
              {userRole === "buyer"
                ? chat.supplierName || "Unknown Supplier"
                : chat.buyerName || "Unknown Buyer"}
            </p>
          </div>
        ))}
      </div>
      <div className='chat-container'>
        <div className='chat-header'>
          <h2>
            {userRole === "buyer"
              ? supplierInfo.name || "Unknown Supplier"
              : buyerInfo}
          </h2>
        </div>
        <div className='product-info'>
          <h4>Product Details</h4>
          <p>
            <strong>Name:</strong> {productDetails.name}
          </p>
          {/* <p>
            <strong>Price:</strong> ${productDetails.price}
          </p> */}
          {/* <p>
            <strong>Minimum Order:</strong> {productDetails.minOrder}
          </p> */}
        </div>
        <div
          className='cart-details'
          style={{
            borderBottom: "1px solid #ccc",
            paddingBottom: "10px",
            marginBottom: "20px",
          }}
        >
          <h4>Cart Details</h4>
          <div>
            <strong>Quantity:</strong>{" "}
            {editingField === "quantity" ? (
              <>
                <input
                  type='number'
                  value={cartDetails.quantity || ""}
                  onChange={(e) =>
                    setCartDetails((prev) => ({
                      ...prev,
                      quantity: parseInt(e.target.value, 10),
                    }))
                  }
                />
                <span
                  style={{
                    color: "green",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => handleSave("quantity")}
                >
                  Save
                </span>
                <span
                  style={{
                    color: "gray",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => setEditingField(null)}
                >
                  Cancel
                </span>
              </>
            ) : (
              <>
                {cartDetails.quantity || "N/A"}{" "}
                {userRole === "supplier" && (
                  <span
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleEdit("quantity")}
                  >
                    Edit
                  </span>
                )}
              </>
            )}
          </div>
          <div>
            <strong>Shipping Cost:</strong>{" "}
            {editingField === "shippingCost" ? (
              <>
                <input
                  type='number'
                  value={cartDetails.shippingCost || ""}
                  onChange={(e) =>
                    setCartDetails((prev) => ({
                      ...prev,
                      shippingCost: parseFloat(e.target.value),
                    }))
                  }
                />
                <span
                  style={{
                    color: "green",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => handleSave("shippingCost")}
                >
                  Save
                </span>
                <span
                  style={{
                    color: "gray",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => setEditingField(null)}
                >
                  Cancel
                </span>
              </>
            ) : (
              <>
                ${cartDetails.shippingCost || "N/A"}{" "}
                {userRole === "supplier" && (
                  <span
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleEdit("shippingCost")}
                  >
                    Edit
                  </span>
                )}
              </>
            )}
          </div>
          <div>
            <strong>Unit Price:</strong>{" "}
            {editingField === "unitPrice" ? (
              <>
                <input
                  type='number'
                  value={cartDetails.unitPrice || ""}
                  onChange={(e) =>
                    setCartDetails((prev) => ({
                      ...prev,
                      unitPrice: parseFloat(e.target.value),
                    }))
                  }
                />
                <span
                  style={{
                    color: "green",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => handleSave("unitPrice")}
                >
                  Save
                </span>
                <span
                  style={{
                    color: "gray",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={() => setEditingField(null)}
                >
                  Cancel
                </span>
              </>
            ) : (
              <>
                ${cartDetails.unitPrice || "N/A"}{" "}
                {userRole === "supplier" && (
                  <span
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleEdit("unitPrice")}
                  >
                    Edit
                  </span>
                )}
              </>
            )}
          </div>
          <div>
            <strong>Total:</strong> $
            {cartDetails.quantity && cartDetails.unitPrice
              ? (
                  cartDetails.quantity * cartDetails.unitPrice +
                  (cartDetails.shippingCost || 0)
                ).toFixed(2)
              : "N/A"}
          </div>
        </div>

        <div className='messages'>
          {messages.map((msg, idx) => (
            <div
              key={idx}
              className={`message ${
                msg.sender === userRole ? "right" : "left"
              }`}
            >
              <p>{msg.content}</p>
              <small>
                {msg.sender === userRole
                  ? userRole === "buyer"
                    ? "You (Buyer)"
                    : "You (Supplier)"
                  : userRole === "buyer"
                  ? supplierInfo.name || "Supplier"
                  : buyerInfo || "Buyer"}
              </small>
              <small>{new Date(msg.timestamp).toLocaleString()}</small>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className='message-input'>
          <input
            type='text'
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                sendMessage();
              }
            }}
            placeholder='Type your message...'
          />
          <div className='message-actions'>
            <button onClick={sendMessage} className='send-button'>
              <Send size={16} />
            </button>
            <label>
              <input
                type='file'
                accept='image/*'
                onChange={(e) => handleFileUpload(e.target.files[0])}
                hidden
              />
              <Image
                size={20}
                className='icon image-icon'
                title='Upload Image'
              />
            </label>
            <label>
              <input
                type='file'
                onChange={(e) => handleFileUpload(e.target.files[0])}
                hidden
              />
              <FileText
                size={20}
                className='icon file-icon'
                title='Upload File'
              />
            </label>
            <button
              className='icon location-icon'
              onClick={() =>
                alert("Attach location functionality coming soon!")
              }
              title='Attach Location'
            >
              <MapPin size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
