import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/config"; // Import Firebase config
import { collection, getDocs } from "firebase/firestore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import constructionImg from "../assets/construction.webp";
import equipmentImg from "../assets/equipment.webp";
import plasticPaperImg from "../assets/plasticandpapers.webp";

const Home = () => {
  const [products, setProducts] = useState([]); // State to hold fetched products
  const [loading, setLoading] = useState(true); // State for loading status

  const navigate = useNavigate();
  const sliderRef = useRef(null); // Ref for controlling the slider

  // Fetch data from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products")); // Replace 'products' with your Firestore collection name
        const productList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productList); // Update state with fetched data
        setLoading(false); // Turn off loading spinner
      } catch (error) {
        console.error("Error fetching products: ", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Disable default arrows to use custom ones
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div>
      {/* Hero Section */}
      <section
        style={{
          backgroundImage: `url('/banner.webp')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "white",
          padding: "100px 0",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(44, 100, 73, 0.85)",
            zIndex: 1,
          }}
        ></div>

        <div
          style={{
            position: "relative",
            zIndex: 2,
            textAlign: "center",
            padding: "0 15px",
          }}
        >
          <div
            style={{
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <h1 style={{ fontSize: "3rem", fontWeight: "bold" }}>
              Effortless Shopping, <br />
              Right at Your Fingertips
            </h1>
            <p style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>
              We’re here to make your shopping experience as comfortable and
              seamless as possible. Discover a platform that unites the finest
              products from across the Kingdom of Saudi Arabia—all in one place.
            </p>
            <p style={{ fontSize: "1.2rem", marginTop: "1rem" }}>
              From family-owned businesses to large-scale enterprises, we bring
              together a diverse range of locally-produced goods with
              user-friendly technology and convenient payment options,
              supporting the Saudi economy and celebrating local industries.
            </p>
          </div>
        </div>
      </section>

      {/* Category Section */}
      <section style={{ padding: "30px", background: "#fff" }}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Shop by Category
        </h2>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "40px",
            marginTop: "20px",
          }}
        >
          <div
            onClick={() => navigate("/category/construction")}
            style={{
              textAlign: "center",
              flex: "1 1 calc(33.33% - 30px)",
              maxWidth: "320px",
              minWidth: "150px",
              margin: "10px",
              cursor: "pointer",
            }}
          >
            <img
              src={constructionImg}
              alt='Construction'
              style={{
                width: "100%",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            <p>Construction</p>
          </div>
          <div
            onClick={() => navigate("/category/equipment")}
            style={{
              textAlign: "center",
              flex: "1 1 calc(33.33% - 30px)",
              maxWidth: "320px",
              minWidth: "150px",
              margin: "10px",
              cursor: "pointer",
            }}
          >
            <img
              src={equipmentImg}
              alt='Equipment'
              style={{
                width: "100%",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            <p>Equipment</p>
          </div>
          <div
            onClick={() => navigate("/category/plastic-and-papers")}
            style={{
              textAlign: "center",
              flex: "1 1 calc(33.33% - 30px)",
              maxWidth: "320px",
              minWidth: "150px",
              margin: "10px",
              cursor: "pointer",
            }}
          >
            <img
              src={plasticPaperImg}
              alt='Plastic and Papers'
              style={{
                width: "100%",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            <p>Plastic and Papers</p>
          </div>
        </div>
      </section>

      {/* Best Sellers Section */}
      <section
        style={{
          padding: "30px",
          background: "#f9f9f9",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h2 style={{ textAlign: "left", margin: 0, color: "#2c6449" }}>
            Best Sellers
          </h2>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <span
              style={{
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "500",
                color: "#2c6449",
                textDecoration: "none",
                paddingBottom: "2px",
                borderBottom: "2px solid #2c6449",
              }}
              onClick={() => navigate("/products")}
            >
              See All Products
            </span>
            <button
              style={{
                width: "40px",
                height: "40px",
                border: "2px solid #2c6449",
                borderRadius: "50%",
                background: "transparent",
                color: "#2c6449",
                cursor: "pointer",
              }}
              onClick={() => sliderRef.current?.slickPrev()}
            >
              &#8592;
            </button>
            <button
              style={{
                width: "40px",
                height: "40px",
                border: "2px solid #2c6449",
                borderRadius: "50%",
                background: "transparent",
                color: "#2c6449",
                cursor: "pointer",
              }}
              onClick={() => sliderRef.current?.slickNext()}
            >
              &#8594;
            </button>
          </div>
        </div>
        {!loading && products.length > 0 && (
          <Slider {...sliderSettings} ref={sliderRef}>
            {products.map((product) => {
              const priceRanges = product.priceRanges || [];
              const prices = priceRanges.map((range) => range.price);
              const lowestPrice = prices.length ? Math.min(...prices) : "N/A";
              const highestPrice = prices.length ? Math.max(...prices) : "N/A";
              const minOrder = priceRanges[0]?.minQty || "N/A";
              const mainImage =
                product.mainImageUrl || "https://via.placeholder.com/300";
              const category = product.category || "Uncategorized";

              return (
                <div key={product.id} style={{ padding: "10px" }}>
                  <div
                    style={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      padding: "0",
                      margin: "10px",
                      width: "90%",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      overflow: "hidden",
                      background: "#fff",
                    }}
                  >
                    {/* Product Image */}
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={mainImage}
                        alt={product.description || "Unnamed Product"}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    {/* Product Details */}
                    <div style={{ padding: "10px" }}>
                      <p
                        style={{
                          fontWeight: "500",
                          margin: "3px 0",
                          fontSize: "12px",
                          color: "#555",
                        }}
                      >
                        {category}
                      </p>
                      <p
                        style={{
                          fontWeight: "600",
                          margin: "5px 0",
                          fontSize: "14px",
                          lineHeight: "1.2",
                        }}
                      >
                        {product.description || "Unnamed Product"}
                      </p>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          margin: "3px 0",
                          lineHeight: "1.2",
                        }}
                      >
                        ${lowestPrice} - ${highestPrice}
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          color: "#888",
                          margin: "3px 0",
                          lineHeight: "1.2",
                        }}
                      >
                        Min. order: {minOrder} piece(s)
                      </p>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <button
                          onClick={() => navigate(`/product/${product.id}`)}
                          style={{
                            padding: "4px 8px",
                            backgroundColor: "#2c6449",
                            color: "#fff",
                            border: "none",
                            borderRadius: "3px",
                            cursor: "pointer",
                            fontSize: "11px",
                          }}
                        >
                          View Details
                        </button>
                        <button
                          style={{
                            padding: "4px 8px",
                            backgroundColor: "#007bff",
                            color: "#fff",
                            border: "none",
                            borderRadius: "3px",
                            cursor: "pointer",
                            fontSize: "11px",
                          }}
                        >
                          Contact Supplier
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        )}
      </section>
    </div>
  );
};

export default Home;
