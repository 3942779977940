import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { getDocuments, addDocument } from "../../utils/firestore";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [activityLog, setActivityLog] = useState([]);
  const [policy, setPolicy] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  const [newSupplier, setNewSupplier] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "+966", // Default to Saudi Arabia
    companyLogo: null, // For file upload
    crNumber: "",
    address: "",
    city: "",
    region: "",
    otherCities: "",
    deliveryType: "Own Delivery", // Default radio value
  });

  const [editUser, setEditUser] = useState(null);

  const [newBuyer, setNewBuyer] = useState({ name: "", email: "", phone: "" });
  const [showAddSupplierForm, setShowAddSupplierForm] = useState(false);
  const [showAddBuyerForm, setShowAddBuyerForm] = useState(false);
  const [editOrder, setEditOrder] = useState(null);

  const handleEditSupplier = (supplier) => {
    setEditUser({
      ...supplier,
      countryCode: supplier.countryCode || "+966",
      deliveryType: supplier.deliveryType || "Own Delivery",
    });
  };

  const handleSaveEdit = async (updatedUser) => {
    try {
      const sanitizedUser = {
        ...updatedUser,
        deliveryType: ["pickup", "delivery", "both"].includes(
          updatedUser.deliveryType
        )
          ? updatedUser.deliveryType
          : "pickup", // Default value
      };

      console.log("Sending updated user data:", sanitizedUser);

      const response = await fetch(
        `https://rankslice.com/edit-supplier/${sanitizedUser.uid}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(sanitizedUser),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Server error:", errorData);
        throw new Error(errorData.error || "Failed to update supplier");
      }

      alert("Supplier updated successfully!");

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.uid === sanitizedUser.uid ? sanitizedUser : user
        )
      );
      setEditUser(null);
    } catch (error) {
      console.error("Error updating supplier:", error.message);
      alert(error.message || "An error occurred while updating the supplier.");
    }
  };

  const handleAddTermsAndConditions = () => {
    navigate("/terms-and-conditions-upload");
  };

  const handleAddPrivacyPolicy = () => {
    navigate("/privacy-policy-upload"); // Update this to the desired route
  };

  // Fetch data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [users, products, orders, activityLog, policies] =
          await Promise.all([
            getDocuments("users"),
            getDocuments("products"),
            getDocuments("orders"),
            getDocuments("activityLog"),
            getDocuments("generalPolicy"),
          ]);

        setUsers(users);
        setProducts(products);
        setOrders(orders);
        setActivityLog(activityLog);
        setPolicy(policies[0]?.content || "No policy set.");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const exportLogs = () => {
    const csvContent = activityLog
      .map((log) =>
        [
          log.action,
          JSON.stringify(log.details),
          log.user || "System",
          new Date(log.timestamp).toLocaleString(),
          log.status,
        ].join(",")
      )
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "activity_logs.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const logActivity = async (action, details = {}) => {
    const log = {
      action,
      details: details.changes?.length
        ? details
        : { note: "No changes detected." },
      timestamp: new Date().toISOString(),
    };
    await addDocument("activityLog", log);
    setActivityLog((prev) => [log, ...prev]);
  };

  const handleAddSupplier = async () => {
    const {
      name,
      phone,
      email,
      crNumber,
      address,
      city,
      region,
      deliveryType,
      companyLogo,
      countryCode,
    } = newSupplier;

    // Validate required fields
    if (
      !name ||
      !phone ||
      !email ||
      !crNumber ||
      !address ||
      !city ||
      !region
    ) {
      alert("Please fill out all required fields!");
      return;
    }

    if (!/^[1-9][0-9]{8}$/.test(phone)) {
      alert("Phone number must be exactly 9 digits and cannot start with 0.");
      return;
    }

    try {
      const formattedPhone = `${countryCode}${phone}`;
      // Create a FormData object to handle file uploads
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone", formattedPhone);
      formData.append("email", email);
      formData.append("crNumber", crNumber);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("region", region);
      formData.append("deliveryType", deliveryType);
      if (companyLogo) formData.append("companyLogo", companyLogo);

      // Call backend API to register supplier
      const response = await fetch("https://rankslice.com/create-supplier", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error from server:", errorData);
        alert(`Failed to register supplier: ${errorData.error}`);
        return;
      }

      const data = await response.json();
      alert(data.message || "Supplier registered successfully!");

      // Reset form
      setNewSupplier({
        name: "",
        email: "",
        phone: "",
        companyLogo: null,
        crNumber: "",
        address: "",
        city: "",
        region: "",
        deliveryType: "Own Delivery",
      });
      setShowAddSupplierForm(false);

      // Optionally refresh users
      fetchUsers();
    } catch (error) {
      console.error("Error registering supplier:", error);
      alert("An error occurred while registering the supplier.");
    }
  };

  const navigate = useNavigate();

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getDocuments("users");
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDeleteSupplier = async (uid) => {
    if (!window.confirm("Are you sure you want to delete this supplier?")) {
      return;
    }

    try {
      const response = await fetch(
        `https://rankslice.com/delete-supplier/${uid}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        const error = await response.json();
        alert(`Failed to delete supplier: ${error.error}`);
        return;
      }

      alert("Supplier deleted successfully!");

      // Update the UI by filtering out the deleted supplier
      setUsers((prevUsers) => prevUsers.filter((user) => user.uid !== uid));
    } catch (error) {
      console.error("Error deleting supplier:", error);
      alert("An error occurred while deleting the supplier.");
    }
  };

  const handleAddBuyer = async () => {
    if (!newBuyer.name || !newBuyer.email || !newBuyer.phone) {
      alert("All fields are required!");
      return;
    }

    const buyer = { ...newBuyer, role: "buyer" };
    await addDocument("users", buyer);
    alert("Buyer added successfully!");
    setUsers((prev) => [...prev, buyer]);
    logActivity("Added Buyer", buyer);
    setNewBuyer({ name: "", email: "", phone: "" });
    setShowAddBuyerForm(false);
  };

  return (
    <div className='container py-5' style={{ backgroundColor: "#f7f7f7" }}>
      <div className='row mb-4'>
        <div className='col'>
          <h2 className='text-success fw-bold'>Admin Dashboard</h2>
          <p className='text-muted'>
            Manage users, products, orders, activity logs, and policies
            efficiently.
          </p>
        </div>
      </div>

      {/* Enhanced Activity Log Section */}
      <div className='row mb-3'>
        <div className='col-12'>
          <div className='card shadow-sm'>
            <div className='card-header text-success fw-bold d-flex justify-content-between align-items-center'>
              <h4 className='text-success fw-bold'>Activity Logs</h4>
              {/* Example filter - can be extended */}
              <input
                type='text'
                className='form-control form-control-sm w-25'
                placeholder='Search logs...'
                onChange={(e) => setSearchQuery(e.target.value)} // Add state for search if needed
              />
            </div>
            <div className='card-body p-2'>
              {/* Scrollable container for long logs */}
              <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <table className='table table-sm table-bordered table-hover'>
                  <thead className='table-light'>
                    <tr>
                      <th>Action</th>
                      <th>Details</th>
                      <th>User</th>
                      <th>Date and Time</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activityLog.length > 0 ? (
                      activityLog
                        .filter((log) =>
                          // Example filtering logic, adjust as needed
                          searchQuery
                            ? log.action
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              JSON.stringify(log.details)
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            : true
                        )
                        .map((log, index) => (
                          <tr key={index}>
                            {/* Action */}
                            <td>{log.action}</td>
                            {/* Details */}
                            <td>
                              {log.details &&
                              log.details.changes &&
                              log.details.changes.length > 0 ? (
                                <ul>
                                  {log.details.changes.map((change, idx) => (
                                    <li key={idx}>
                                      <strong>
                                        {change.fieldName || "Field"}:
                                      </strong>{" "}
                                      {change?.oldValue !== undefined
                                        ? change.oldValue
                                        : "N/A"}{" "}
                                      →{" "}
                                      {change?.newValue !== undefined
                                        ? change.newValue
                                        : "N/A"}
                                    </li>
                                  ))}
                                </ul>
                              ) : log.details?.note ? (
                                <span>{log.details.note}</span>
                              ) : (
                                "No details available"
                              )}
                            </td>

                            {/* User */}
                            <td>{log.user || "System"}</td>
                            {/* Timestamp */}
                            <td>{new Date(log.timestamp).toLocaleString()}</td>
                            {/* Status */}
                            <td>
                              <span
                                className={`badge ${
                                  log.status === "Success"
                                    ? "bg-success"
                                    : log.status === "Failed"
                                    ? "bg-danger"
                                    : "bg-secondary"
                                }`}
                              >
                                {log.status}
                              </span>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan='5' className='text-center'>
                          No activity logged.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* Example Export Button */}
              <div className='text-end mt-2'>
                <button
                  className='btn btn-sm btn-outline-primary'
                  onClick={exportLogs} // Implement export functionality
                >
                  Export Logs
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* All Products */}
      <div className='row mb-4'>
        <div className='col-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4
                className='fw-bold d-flex justify-content-between align-items-center'
                style={{ color: "#2c6449" }}
              >
                All Products{" "}
              </h4>
              <button
                className='btn btn-success btn-sm'
                onClick={() => navigate("/admin-add-products")}
              >
                Add Product
              </button>
              <div className='table-responsive'>
                <table className='table table-sm table-hover'>
                  <thead className='table-light'>
                    <tr>
                      <th>Name</th>
                      <th>Location</th>
                      <th>Quantity & Pricing</th>
                      <th>Supplier Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product) => (
                      <tr key={product.id}>
                        <td>{product.productName}</td>
                        <td>{product.mainLocation}</td>
                        <td>
                          {product.priceRanges &&
                            product.priceRanges.map((range, index) => (
                              <div key={index} className='small'>
                                {`Min: ${range.minQty}, Max: ${range.maxQty}, $${range.price}`}
                              </div>
                            ))}
                        </td>
                        <td>{product.supplierName}</td>
                        <td>
                          <button
                            className='btn btn-primary btn-sm me-1'
                            onClick={() =>
                              navigate(`/admin-edit-products/${product.id}`)
                            }
                          >
                            Edit
                          </button>

                          <button className='btn btn-danger btn-sm'>
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* All Suppliers */}
      <div className='row mb-4'>
        <div className='col-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='text-success fw-bold'>All Suppliers</h4>
              <button
                className='btn btn-success btn-sm mb-3'
                onClick={() => setShowAddSupplierForm(!showAddSupplierForm)}
              >
                {showAddSupplierForm ? "Cancel Add Supplier" : "Add Supplier"}
              </button>
              {showAddSupplierForm && (
                <div className='mb-3'>
                  <div className='row'>
                    {/* Left Column */}
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='Name'
                        value={newSupplier.name}
                        onChange={(e) =>
                          setNewSupplier({
                            ...newSupplier,
                            name: e.target.value,
                          })
                        }
                      />
                      <input
                        type='email'
                        className='form-control form-control-sm mb-2'
                        placeholder='Email'
                        value={newSupplier.email}
                        onChange={(e) =>
                          setNewSupplier({
                            ...newSupplier,
                            email: e.target.value,
                          })
                        }
                      />
                      <div style={{ display: "flex", gap: "8px" }}>
                        <select
                          value={newSupplier.countryCode || "+966"}
                          onChange={(e) =>
                            setNewSupplier({
                              ...newSupplier,
                              countryCode: e.target.value,
                            })
                          }
                          style={{
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            flex: "0 0 100px",
                          }}
                        >
                          {/* GCC Country Codes */}
                          <option value='+971'>+971</option>
                          <option value='+973'>+973</option>
                          <option value='+968'>+968</option>
                          <option value='+974'>+974</option>
                          <option value='+966'>+966</option>
                          <option value='+965'>+965</option>
                        </select>
                        <input
                          type='text'
                          placeholder='Phone Number'
                          value={newSupplier.phone}
                          onChange={(e) => {
                            const phone = e.target.value;
                            if (
                              /^[1-9][0-9]{0,8}$/.test(phone) ||
                              phone === ""
                            ) {
                              setNewSupplier({
                                ...newSupplier,
                                phone,
                              });
                            }
                          }}
                          style={{
                            flex: "1",
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        />
                      </div>

                      <div className='mt-2'>
                        <input
                          type='file'
                          className='form-control form-control-sm mb-2'
                          onChange={(e) =>
                            setNewSupplier({
                              ...newSupplier,
                              companyLogo: e.target.files[0],
                            })
                          }
                        />
                      </div>

                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='CR Number'
                        value={newSupplier.crNumber}
                        onChange={(e) => {
                          const input = e.target.value;
                          // Allow only digits and limit to 10 characters
                          if (/^\d{0,10}$/.test(input)) {
                            setNewSupplier({
                              ...newSupplier,
                              crNumber: input,
                            });
                          }
                        }}
                      />

                      {/* Save Button Aligned Left */}
                      <div className='mt-3'>
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={handleAddSupplier}
                        >
                          Save Supplier
                        </button>
                      </div>
                    </div>

                    {/* Right Column */}
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='Address'
                        value={newSupplier.address}
                        onChange={(e) =>
                          setNewSupplier({
                            ...newSupplier,
                            address: e.target.value,
                          })
                        }
                      />
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='City'
                        value={newSupplier.city}
                        onChange={(e) =>
                          setNewSupplier({
                            ...newSupplier,
                            city: e.target.value,
                          })
                        }
                      />
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='Region'
                        value={newSupplier.region}
                        onChange={(e) =>
                          setNewSupplier({
                            ...newSupplier,
                            region: e.target.value,
                          })
                        }
                      />
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='Other Cities Served'
                        value={newSupplier.otherCities}
                        onChange={(e) =>
                          setNewSupplier({
                            ...newSupplier,
                            otherCities: e.target.value,
                          })
                        }
                      />
                      <div>
                        <label>
                          <input
                            type='radio'
                            name='deliveryType'
                            value='pickup'
                            checked={editUser.deliveryType === "pickup"}
                            onChange={(e) =>
                              setEditUser({
                                ...editUser,
                                deliveryType: e.target.value,
                              })
                            }
                          />
                          Pickup
                        </label>
                        <label>
                          <input
                            type='radio'
                            name='deliveryType'
                            value='delivery'
                            checked={editUser.deliveryType === "delivery"}
                            onChange={(e) =>
                              setEditUser({
                                ...editUser,
                                deliveryType: e.target.value,
                              })
                            }
                          />
                          Delivery
                        </label>
                        <label>
                          <input
                            type='radio'
                            name='deliveryType'
                            value='both'
                            checked={editUser.deliveryType === "both"}
                            onChange={(e) =>
                              setEditUser({
                                ...editUser,
                                deliveryType: e.target.value,
                              })
                            }
                          />
                          Both
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className='table-responsive'>
                <table className='table table-sm table-hover'>
                  <thead className='table-light'>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users
                      .filter((user) => user.role === "supplier")
                      .map((supplier) => (
                        <tr key={supplier.id}>
                          <td>{supplier.name}</td>
                          <td>{supplier.email}</td>
                          <td>{supplier.phone}</td>
                          <td>
                            <button
                              className='btn btn-primary btn-sm me-1'
                              onClick={() => handleEditSupplier(supplier)}
                            >
                              Edit
                            </button>

                            <button
                              className='btn btn-danger btn-sm'
                              onClick={() => handleDeleteSupplier(supplier.uid)}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {editUser && (
                <div className='mt-3'>
                  <h6>Edit Supplier</h6>

                  <div className='row'>
                    {/* Left Column */}
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='Name'
                        value={editUser.name}
                        onChange={(e) =>
                          setEditUser({ ...editUser, name: e.target.value })
                        }
                      />
                      <input
                        type='email'
                        className='form-control form-control-sm mb-2'
                        placeholder='Email'
                        value={editUser.email}
                        onChange={(e) =>
                          setEditUser({ ...editUser, email: e.target.value })
                        }
                      />
                      <div style={{ display: "flex", gap: "8px" }}>
                        <select
                          value={editUser.countryCode || "+966"}
                          onChange={(e) =>
                            setEditUser({
                              ...editUser,
                              countryCode: e.target.value,
                            })
                          }
                          style={{
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            flex: "0 0 100px",
                          }}
                        >
                          <option value='+971'>+971</option>
                          <option value='+973'>+973</option>
                          <option value='+968'>+968</option>
                          <option value='+974'>+974</option>
                          <option value='+966'>+966</option>
                          <option value='+965'>+965</option>
                        </select>
                        <input
                          type='text'
                          placeholder='Phone Number'
                          value={editUser.phone}
                          onChange={(e) =>
                            setEditUser({ ...editUser, phone: e.target.value })
                          }
                          style={{
                            flex: "1",
                            padding: "8px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                      <div className='mt-2'>
                        <input
                          type='file'
                          className='form-control form-control-sm mb-2'
                          onChange={(e) =>
                            setEditUser({
                              ...editUser,
                              companyLogo: e.target.files[0],
                            })
                          }
                        />
                      </div>
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='CR Number'
                        value={editUser.crNumber}
                        onChange={(e) =>
                          setEditUser({ ...editUser, crNumber: e.target.value })
                        }
                      />
                    </div>

                    {/* Right Column */}
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='Address'
                        value={editUser.address}
                        onChange={(e) =>
                          setEditUser({ ...editUser, address: e.target.value })
                        }
                      />
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='City'
                        value={editUser.city}
                        onChange={(e) =>
                          setEditUser({ ...editUser, city: e.target.value })
                        }
                      />
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='Region'
                        value={editUser.region}
                        onChange={(e) =>
                          setEditUser({ ...editUser, region: e.target.value })
                        }
                      />
                      <input
                        type='text'
                        className='form-control form-control-sm mb-2'
                        placeholder='Other Cities Served'
                        value={editUser.otherCities}
                        onChange={(e) =>
                          setEditUser({
                            ...editUser,
                            otherCities: e.target.value,
                          })
                        }
                      />
                      <div className='mb-2'>
                        <label className='form-label'>Delivery Type:</label>
                        <div>
                          <input
                            type='radio'
                            name='deliveryType'
                            value='Own Delivery'
                            checked={editUser.deliveryType === "Own Delivery"}
                            onChange={(e) =>
                              setEditUser({
                                ...editUser,
                                deliveryType: e.target.value,
                              })
                            }
                          />{" "}
                          Own Delivery
                          <input
                            type='radio'
                            name='deliveryType'
                            value='Outside Delivery'
                            className='ms-2'
                            checked={
                              editUser.deliveryType === "Outside Delivery"
                            }
                            onChange={(e) =>
                              setEditUser({
                                ...editUser,
                                deliveryType: e.target.value,
                              })
                            }
                          />{" "}
                          Outside Delivery
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      className='btn btn-success btn-sm me-1'
                      onClick={() => handleSaveEdit(editUser)}
                    >
                      Save
                    </button>
                    <button
                      className='btn btn-secondary btn-sm'
                      onClick={() => setEditUser(null)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* All Buyers */}
      <div className='row mb-4'>
        <div className='col-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='text-success fw-bold'>All Buyers</h4>
              <button
                className='btn btn-success btn-sm mb-3'
                onClick={() => setShowAddBuyerForm(!showAddBuyerForm)}
              >
                {showAddBuyerForm ? "Cancel Add Buyer" : "Add Buyer"}
              </button>
              {showAddBuyerForm && (
                <div className='mb-3'>
                  <input
                    type='text'
                    className='form-control form-control-sm mb-2'
                    placeholder='Name'
                    value={newBuyer.name}
                    onChange={(e) =>
                      setNewBuyer({ ...newBuyer, name: e.target.value })
                    }
                  />
                  <input
                    type='email'
                    className='form-control form-control-sm mb-2'
                    placeholder='Email'
                    value={newBuyer.email}
                    onChange={(e) =>
                      setNewBuyer({ ...newBuyer, email: e.target.value })
                    }
                  />
                  <input
                    type='text'
                    className='form-control form-control-sm mb-2'
                    placeholder='Phone'
                    value={newBuyer.phone}
                    onChange={(e) =>
                      setNewBuyer({ ...newBuyer, phone: e.target.value })
                    }
                  />
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={handleAddBuyer}
                  >
                    Save Buyer
                  </button>
                </div>
              )}
              <div className='table-responsive'>
                <table className='table table-sm table-hover'>
                  <thead className='table-light'>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users
                      .filter((user) => user.role === "buyer")
                      .map((buyer) => (
                        <tr key={buyer.id}>
                          <td>{buyer.name}</td>
                          <td>{buyer.email}</td>
                          <td>{buyer.phone}</td>
                          <td>
                            <button
                              className='btn btn-primary btn-sm me-1'
                              onClick={() => setEditUser(buyer)}
                            >
                              Edit
                            </button>
                            <button className='btn btn-danger btn-sm'>
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {editUser && (
                <div className='mt-3'>
                  <h6>Edit Buyer</h6>
                  <input
                    type='text'
                    className='form-control form-control-sm mb-2'
                    placeholder='Name'
                    value={editUser.name}
                    onChange={(e) =>
                      setEditUser({ ...editUser, name: e.target.value })
                    }
                  />
                  <input
                    type='email'
                    className='form-control form-control-sm mb-2'
                    placeholder='Email'
                    value={editUser.email}
                    onChange={(e) =>
                      setEditUser({ ...editUser, email: e.target.value })
                    }
                  />
                  <input
                    type='text'
                    className='form-control form-control-sm mb-2'
                    placeholder='Phone'
                    value={editUser.phone}
                    onChange={(e) =>
                      setEditUser({ ...editUser, phone: e.target.value })
                    }
                  />
                  <div>
                    <button className='btn btn-success btn-sm me-1'>
                      Save
                    </button>
                    <button
                      className='btn btn-secondary btn-sm'
                      onClick={() => setEditUser(null)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* All Orders */}
      <div className='row mb-4'>
        <div className='col-12'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h4 className='text-success fw-bold'>All Orders</h4>
              <div className='table-responsive'>
                <table className='table table-sm table-hover'>
                  <thead className='table-light'>
                    <tr>
                      <th>Order ID</th>
                      <th>Buyer ID</th>
                      <th>Status</th>
                      <th>Total</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => (
                      <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>{order.buyerId}</td>
                        <td>{order.status}</td>
                        <td>${order.totalAmount}</td>
                        <td>
                          <button
                            className='btn btn-primary btn-sm me-1'
                            onClick={() => setEditOrder(order)}
                          >
                            Edit
                          </button>
                          <button className='btn btn-danger btn-sm'>
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {editOrder && (
                <div className='mt-3'>
                  <h6>Edit Order</h6>
                  <input
                    type='text'
                    className='form-control form-control-sm mb-2'
                    value={editOrder.status}
                    onChange={(e) =>
                      setEditOrder({ ...editOrder, status: e.target.value })
                    }
                  />
                  <div>
                    <button className='btn btn-success btn-sm me-1'>
                      Save
                    </button>
                    <button
                      className='btn btn-secondary btn-sm'
                      onClick={() => setEditOrder(null)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* General Policy Section */}
      <div className='row mb-3'>
        <div className='col-12'>
          <div className='card shadow-sm'>
            <div className='card-header text-success fw-bold'>
              <h4 className='text-success fw-bold'>General Policy</h4>
            </div>
            <div className='card-body'>
              {/* Display fetched general policy */}
              <p>{policy || "No policy set."}</p>

              {/* Buttons to Add/Edit Terms & Conditions or Privacy Policy */}
              <div className='mt-3'>
                <button
                  className='btn btn-outline-success btn-sm me-2'
                  onClick={handleAddTermsAndConditions}
                >
                  Add Terms & Conditions
                </button>
                <button
                  className='btn btn-outline-success btn-sm'
                  onClick={handleAddPrivacyPolicy}
                >
                  Add Privacy Policy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
