import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    let userId = localStorage.getItem("currentUserId");
    if (!userId) {
      console.warn("No currentUserId found in localStorage. Using guest.");
      userId = "guest"; // Fallback to guest ID
    }
    const storedCart = localStorage.getItem(`cartItems_${userId}`);
    console.log("Initializing cart for user:", userId, "Cart:", storedCart);
    return storedCart ? JSON.parse(storedCart) : [];
  });

  useEffect(() => {
    const userId = localStorage.getItem("currentUserId") || "guest";
    console.log(`Saving cart for user ${userId}:`, cartItems);
    localStorage.setItem(`cartItems_${userId}`, JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(
        (cartItem) => cartItem.id === item.id
      );

      if (existingItem) {
        const updatedItems = prevItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + item.quantity }
            : cartItem
        );
        console.log("Updated Cart Items:", updatedItems);
        return updatedItems;
      }

      const newCart = [...prevItems, item];
      console.log("New Cart Items:", newCart);
      return newCart;
    });
  };

  const removeFromCart = (itemId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const clearCart = () => {
    setCartItems([]);
    const userId = localStorage.getItem("currentUserId") || "guest";
    console.log(`Clearing cart for user: ${userId}`);
    localStorage.removeItem(`cartItems_${userId}`);
  };

  const value = {
    cartItems,
    addToCart,
    removeFromCart,
    clearCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
