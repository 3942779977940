// StyledComponents.js
import styled from "styled-components";

export const PrimaryButton = styled.button`
  background-color: #2c6449; /* Your brand color */
  color: white;
  border: 1px solid #2c6449;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #245b3d; /* Slightly darker shade on hover */
  }

  &:disabled {
    opacity: 0.6; /* Dimmed appearance when disabled */
    cursor: not-allowed;
  }
`;

export const SecondaryButton = styled.button`
  background-color: #2980b9; /* Light gray for secondary buttons */
  color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  padding: 6px 14px; !important
  border-radius: 5px; !important
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d5d5d5;
  }
`;

export const DangerButton = styled(PrimaryButton)`
  background-color: #e74c3c; /* Bootstrap danger color */
  color: white;
  font-size: 14px;
  border: 1px solid #ccc;
  padding: 6px 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #b52a37;
  }
`;
