import React, { useState } from "react";
import { useCart } from "../context/CartContext";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import Notification from "../components/shared/Notification";
import LoadingSpinner from "../components/shared/LoadingSpinner"; // Importing the spinner component

const CartPage = () => {
  const { cartItems, removeFromCart, clearCart } = useCart();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [editedQuantities, setEditedQuantities] = useState({});
  const [originalQuantities, setOriginalQuantities] = useState({});
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false); // Loading state for the spinner

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const fetchBuyerName = async (buyerId) => {
    if (!buyerId) return "Unknown Buyer";
    try {
      const userDoc = await getDoc(doc(db, "users", buyerId));
      return userDoc.exists()
        ? userDoc.data().name || "Unknown Buyer"
        : "Unknown Buyer";
    } catch (error) {
      console.error("Error fetching buyer name:", error);
      return "Unknown Buyer";
    }
  };

  const handleContactSupplier = async (supplierId, productId) => {
    if (!currentUser?.uid) {
      showNotification(
        "Login Required",
        "You need to be logged in to contact the supplier."
      );
      return;
    }

    const buyerId = currentUser.uid;
    const buyerName = await fetchBuyerName(buyerId);

    const relevantCartItems = cartItems.filter(
      (item) => item.supplierId === supplierId && item.id === productId
    );

    if (relevantCartItems.length === 0) {
      showNotification(
        "Error",
        "No relevant cart items found for this supplier."
      );
      return;
    }

    const cartItem = relevantCartItems[0];
    const supplierName = cartItem.supplierName || "Unknown Supplier";
    const chatDocId = `chat_${buyerId}_${supplierId}_${productId}`;
    const chatRef = doc(db, "chats", chatDocId);

    try {
      setLoading(true); // Start loading spinner
      const chatSnapshot = await getDoc(chatRef);
      if (!chatSnapshot.exists()) {
        await setDoc(chatRef, {
          buyerId,
          buyerName,
          supplierId,
          supplierName,
          productId,
          productName: cartItem.name,
          productPrice: cartItem.price,
          minOrder: cartItem.minOrder || 1,
          cartDetails: {
            quantity: cartItem.quantity,
            unitPrice: cartItem.price,
            shippingCost: cartItem.shippingCost,
          },
          createdAt: new Date().toISOString(),
          messages: [],
        });
      }
      setLoading(false); // Stop loading spinner
      navigate(`/chat/${chatDocId}`);
    } catch (error) {
      setLoading(false); // Stop loading spinner on error
      console.error("Error creating chat document:", error);
      showNotification(
        "Error",
        "Unable to contact the supplier. Please try again later."
      );
    }
  };

  const handleCheckout = () => {
    if (!currentUser) {
      showNotification(
        "Login Required",
        "You need to be logged in to proceed to checkout."
      );
      navigate("/login");
      return;
    }
    navigate("/checkout");
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    if (isNaN(newQuantity) || newQuantity <= 0) {
      showNotification(
        "Invalid Quantity",
        "Quantity must be a positive number."
      );
      return;
    }

    setEditedQuantities((prev) => ({
      ...prev,
      [itemId]: parseInt(newQuantity, 10),
    }));
  };

  const handleBlur = (itemId, currentQuantity) => {
    const updatedQuantity = editedQuantities[itemId];
    if (updatedQuantity < currentQuantity) {
      showNotification(
        "Invalid Quantity",
        "The quantity is below the minimum. Please add more."
      );
      setEditedQuantities((prev) => ({
        ...prev,
        [itemId]: currentQuantity,
      }));
    }
  };

  const applyChanges = (itemId) => {
    const updatedQuantity = editedQuantities[itemId];
    if (updatedQuantity) {
      const itemIndex = cartItems.findIndex((item) => item.id === itemId);
      if (itemIndex !== -1) {
        cartItems[itemIndex].quantity = updatedQuantity; // Update in context directly
      }
      showNotification("Success", "Quantity updated successfully.");
    }
  };

  if (!cartItems?.length) {
    return <p>Your cart is empty.</p>;
  }

  const groupBySupplier = (items) => {
    return items.reduce((groups, item) => {
      const supplier = item.supplierName || "Unknown Supplier";
      groups[supplier] = groups[supplier] || [];
      groups[supplier].push(item);
      return groups;
    }, {});
  };

  const groupedItems = groupBySupplier(cartItems);

  return (
    <div className='container my-4'>
      {loading && <LoadingSpinner />} {/* Show spinner while loading */}
      <h2>Your Cart</h2>
      {Object.entries(groupedItems).map(([supplier, items], index) => (
        <div key={index} className='mb-4'>
          <h4>Supplier: {supplier}</h4>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th>Item</th>
                <th>Color</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Shipping</th>
                <th>Subtotal</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.name}</td>
                  <td>{item.color || "Not selected"}</td>
                  <td>{item.size || "Not selected"}</td>
                  <td>
                    <input
                      type='number'
                      className='form-control form-control-sm'
                      value={editedQuantities[item.id] || item.quantity}
                      onFocus={() =>
                        setOriginalQuantities((prev) => ({
                          ...prev,
                          [item.id]: item.quantity,
                        }))
                      }
                      onChange={(e) =>
                        handleQuantityChange(item.id, e.target.value)
                      }
                      onBlur={() =>
                        handleBlur(item.id, originalQuantities[item.id])
                      }
                    />
                  </td>
                  <td>${item.price.toFixed(2)}</td>
                  <td>${item.shippingCost.toFixed(2)}</td>
                  <td>
                    $
                    {(
                      (editedQuantities[item.id] || item.quantity) *
                        item.price +
                      item.shippingCost
                    ).toFixed(2)}
                  </td>
                  <td>
                    <button
                      className='btn btn-success btn-sm me-2'
                      onClick={() => applyChanges(item.id)}
                    >
                      Save
                    </button>
                    <button
                      className='btn btn-primary btn-sm me-2'
                      onClick={() =>
                        handleContactSupplier(item.supplierId, item.id)
                      }
                    >
                      Contact Supplier
                    </button>
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={() => removeFromCart(item.id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
      <button className='btn btn-danger mt-3' onClick={clearCart}>
        Clear Cart
      </button>
      <button className='btn btn-primary mt-3 ms-3' onClick={handleCheckout}>
        Proceed to Checkout
      </button>
      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={3000} // Auto-close after 3 seconds
      />
    </div>
  );
};

export default CartPage;
