import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";

const SupplierProductsPage = () => {
  const { supplierId } = useParams(); // Get supplierId from the route
  const navigate = useNavigate(); // Initialize navigate
  const [products, setProducts] = useState([]); // State to hold all products
  const [loading, setLoading] = useState(true); // State for loading status
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered product list
  const [activeTab, setActiveTab] = useState("Newest"); // Default active tab

  // Fetch products for the supplier
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("Fetching products for supplierId:", supplierId); // Debugging
        const productsRef = collection(db, "products");
        const q = query(productsRef, where("supplierId", "==", supplierId));
        const querySnapshot = await getDocs(q);

        const productList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("Fetched products:", productList); // Debugging

        setProducts(productList);
        setFilteredProducts(productList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [supplierId]);

  // Apply sorting based on the active tab
  useEffect(() => {
    let sortedProducts = [...products];
    switch (activeTab) {
      case "Lowest":
        sortedProducts.sort(
          (a, b) =>
            (a.priceRanges?.[0]?.price || 0) - (b.priceRanges?.[0]?.price || 0)
        );
        break;
      case "Highest":
        sortedProducts.sort(
          (a, b) =>
            (b.priceRanges?.[0]?.price || 0) - (a.priceRanges?.[0]?.price || 0)
        );
        break;
      case "Newest":
        sortedProducts.sort((a, b) => b.createdAt - a.createdAt); // Assuming `createdAt` is a timestamp
        break;
      case "Oldest":
        sortedProducts.sort((a, b) => a.createdAt - b.createdAt); // Assuming `createdAt` is a timestamp
        break;
      case "Locations":
        sortedProducts.sort((a, b) =>
          (a.mainLocation || "").localeCompare(b.mainLocation || "")
        );
        break;
      default:
        break;
    }
    setFilteredProducts(sortedProducts);
  }, [activeTab, products]);

  if (loading) {
    return <p style={{ textAlign: "center" }}>Loading products...</p>;
  }

  if (products.length === 0) {
    return (
      <p style={{ textAlign: "center" }}>
        No products available for this supplier.
      </p>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
      {/* Header Row */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        {/* Title */}
        <h2
          style={{
            fontSize: "18px",
            fontWeight: "500",
            color: "#2c6449",
            margin: 0,
          }}
        >
          Total {filteredProducts.length} Products from Supplier
        </h2>

        {/* Tabs for Filters */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: "10px",
            width: "100%",
          }}
        >
          {["Newest", "Oldest", "Lowest", "Highest", "Locations"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              style={{
                padding: "8px 15px",
                background: activeTab === tab ? "#2c6449" : "transparent",
                color: activeTab === tab ? "#fff" : "#555",
                border: "1px solid #ddd",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: activeTab === tab ? "bold" : "normal",
              }}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      {/* Products Grid */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {filteredProducts.map((product) => {
          const priceRanges = product.priceRanges || [];
          const prices = priceRanges.map((range) => range.price);
          const lowestPrice = prices.length ? Math.min(...prices) : "N/A";
          const highestPrice = prices.length ? Math.max(...prices) : "N/A";
          const minOrder = priceRanges[0]?.minQty || "N/A";
          const mainImage =
            product.mainImageUrl || "https://via.placeholder.com/300";
          const category = product.category || "Uncategorized";

          return (
            <div
              key={product.id}
              style={{
                border: "1px solid #ddd",
                borderRadius: "10px",
                overflow: "hidden",
                background: "#fff",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Product Image */}
              <div
                style={{
                  height: "200px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={mainImage}
                  alt={product.productName || "Unnamed Product"}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>

              {/* Product Details */}
              <div style={{ padding: "15px" }}>
                {/* Category */}
                <p
                  style={{
                    fontWeight: "500",
                    margin: "3px 0",
                    fontSize: "12px",
                    color: "#555",
                  }}
                >
                  {category}
                </p>

                {/* Product Name */}
                <p
                  style={{
                    fontWeight: "600",
                    margin: "5px 0",
                    fontSize: "16px",
                    lineHeight: "1.2",
                  }}
                >
                  {product.productName || "Unnamed Product"}
                </p>

                {/* Price Range */}
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    margin: "3px 0",
                    lineHeight: "1.2",
                  }}
                >
                  ${lowestPrice} - ${highestPrice}
                </p>

                {/* Minimum Order */}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#888",
                    margin: "3px 0",
                    lineHeight: "1.2",
                  }}
                >
                  Min. order: {minOrder} piece(s)
                </p>

                {/* Main Location */}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#666",
                    margin: "3px 0",
                    lineHeight: "1.2",
                  }}
                >
                  Origin: {product.mainLocation || "Location not specified"}
                </p>

                {/* Action Button */}
                <button
                  onClick={() => navigate(`/product/${product.id}`)} // Navigate to product details
                  style={{
                    padding: "8px 15px",
                    backgroundColor: "#2c6449",
                    color: "#fff",
                    border: "none",
                    borderRadius: "3px",
                    cursor: "pointer",
                    fontSize: "13px",
                    marginTop: "10px",
                    width: "100%",
                  }}
                >
                  View Details
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SupplierProductsPage;
