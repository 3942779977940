import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase/config";
import {
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  signOut,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Navigate, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null); // Firebase user object
  const [userData, setUserData] = useState(null); // Firestore user data
  const [role, setRole] = useState(null); // Role extracted from Firestore
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [intendedRoute, setIntendedRoute] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const setUserAndRole = async (user) => {
      setCurrentUser(user);
      if (user) {
        try {
          // Persist the session
          await setPersistence(auth, browserLocalPersistence);

          // Fetch token
          const idToken = await user.getIdToken();
          setToken(idToken);

          // Fetch user Firestore data
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUserData(userData); // Store Firestore user data
            setRole(userData.role); // Store role for existing functionality
            setCurrentUser({ ...user, ...userData }); // Merge Firebase user with Firestore data
          } else {
            console.warn("No document found for user in Firestore.");
            setUserData({ role: "guest" }); // Default role
            setRole("guest");
          }

          // Navigate to intended route if available
          if (intendedRoute) {
            navigate(intendedRoute);
            setIntendedRoute(null);
          }
        } catch (error) {
          console.error("Error setting user, token, or role:", error);
        }
      } else {
        setRole(null);
        setUserData(null);
        setToken(null);
        setCurrentUser(null);
      }
      setLoading(false);
    };

    // Listen for Firebase auth changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      setUserAndRole(user);
    });

    return unsubscribe;
  }, [intendedRoute, navigate]);

  // Logout function
  const logout = async () => {
    try {
      await signOut(auth);

      // Clear all states
      setCurrentUser(null);
      setUserData(null);
      setRole(null);
      setToken(null);

      // Navigate to appropriate login page
      if (role === "admin") {
        navigate("/admin-login");
      } else {
        navigate("/user-login");
      }

      // Optional: Force a page reload to clear potential component-level cache
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // Role validation
  const hasRole = (requiredRole) => role === requiredRole;

  // Context value
  const value = {
    currentUser,
    userData, // Full user data from Firestore
    role,
    token,
    loading,
    hasRole,
    setIntendedRoute: (path) => {
      if (!intendedRoute) {
        setIntendedRoute(path);
      }
    },
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const ProtectedRoute = ({ children, requiredRole }) => {
  const { currentUser, loading, setIntendedRoute, hasRole } = useAuth();
  const location = window.location.pathname;

  if (loading) {
    return <div className='spinner-border text-primary' role='status'></div>;
  }

  if (!currentUser) {
    setIntendedRoute(location);
    return <Navigate to='/login' />;
  }

  if (requiredRole && !hasRole(requiredRole)) {
    return <Navigate to='/' />;
  }

  return children;
};
