import React, { useState, useEffect, useReducer, memo } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/config";
import Icon from "feather-icons-react";
import "bootstrap/dist/css/bootstrap.min.css";

// Reusable Input Component
const InputField = memo(
  ({ label, id, name, value, onChange, type = "text", required = false }) => (
    <div className='mb-2'>
      <label htmlFor={id} className='form-label small'>
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        className='form-control form-control-sm'
        value={value}
        onChange={onChange}
        required={required}
      />
    </div>
  )
);

// Initial Form Data State
const initialFormState = {
  name: "",
  email: "",
  phone: "",
  crNumber: "",
  address: "",
  city: "",
  region: "",
  deliveryType: "Own Delivery",
  status: "Active",
  companyLogo: null,
  countryCode: "+966",
};

// Form Reducer
const formReducer = (state, action) => {
  switch (action.type) {
    case "RESET":
      return initialFormState;
    case "UPDATE_FIELD":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

const Suppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [formState, dispatchForm] = useReducer(formReducer, initialFormState);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFormData, setEditFormData] = useState({});

  // Fetch suppliers from Firestore
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const q = query(
          collection(db, "users"),
          where("role", "==", "supplier")
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const suppliersData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSuppliers(suppliersData);
        } else {
          console.log("No suppliers found in the database.");
        }
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuppliers();
  }, []);

  // Handle delete supplier
  const handleDelete = async (supplierId) => {
    if (!window.confirm("Are you sure you want to delete this supplier?"))
      return;

    try {
      const response = await fetch(
        `https://rankslice.com/delete-supplier/${supplierId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setSuppliers((prev) =>
          prev.filter((supplier) => supplier.id !== supplierId)
        );
        alert("Supplier deleted successfully!");
      } else {
        const data = await response.json();
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error("Error deleting supplier:", error);
      alert("Failed to delete supplier.");
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      email,
      phone,
      crNumber,
      address,
      city,
      region,
      deliveryType,
      countryCode,
    } = formState;

    // Ensure all required fields are present
    if (
      !name ||
      !phone ||
      !email ||
      !crNumber ||
      !address ||
      !city ||
      !region
    ) {
      alert("Please fill out all required fields!");
      return;
    }

    const formattedPhone = `${countryCode}${phone.trim()}`;
    if (!/^\+[1-9]\d{1,14}$/.test(formattedPhone)) {
      alert("Invalid phone number format.");
      return;
    }

    try {
      const body = JSON.stringify({
        name,
        phone: formattedPhone,
        email,
        crNumber,
        address,
        city,
        region,
        deliveryType,
      });

      const response = await fetch("https://rankslice.com/create-supplier", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      if (response.ok) {
        const data = await response.json();
        setSuppliers((prev) => [...prev, data]);
        alert("Supplier created successfully!");
        dispatchForm({ type: "RESET" });
        setShowForm(false);
      } else {
        const error = await response.json();
        console.error("Server error:", error);
        alert(`Error: ${error.message || "Failed to create supplier"}`);
      }
    } catch (error) {
      console.error("Error creating supplier:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  // Handle edit supplier
  const handleEdit = (supplier) => {
    setEditFormData({ ...supplier });
    setShowEditModal(true);
  };

  // Handle edit modal save
  const handleSaveEdit = async () => {
    try {
      const response = await fetch(
        `https://rankslice.com/edit-supplier/${editFormData.id}`, //Working Edit Route
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(editFormData),
        }
      );

      if (response.ok) {
        setSuppliers((prev) =>
          prev.map((supplier) =>
            supplier.id === editFormData.id ? editFormData : supplier
          )
        );
        alert("Supplier updated successfully!");
        setShowEditModal(false);
      } else {
        alert("Failed to update supplier.");
      }
    } catch (error) {
      console.error("Error updating supplier:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className='container-fluid py-3'>
      <div className='mb-4'>
        <h4 className='text-success fw-bold'>Suppliers</h4>
        <p className='text-muted small'>Manage and view all suppliers</p>
      </div>

      <div className='d-flex justify-content-between align-items-center mb-3'>
        <button
          className='btn btn-success btn-sm'
          onClick={() => {
            setShowForm(!showForm);
            dispatchForm({ type: "RESET" });
          }}
        >
          <Icon icon='plus' className='me-2' />
          {showForm ? "Cancel" : "Add New Supplier"}
        </button>
      </div>

      {showForm && (
        <div className='card mb-4'>
          <div className='card-body'>
            <h5 className='card-title'>
              {formState.id ? "Edit Supplier" : "Add New Supplier"}
            </h5>
            <form onSubmit={handleSubmit}>
              <div className='row g-2'>
                {/* Left Column */}
                <div className='col-md-6'>
                  <InputField
                    label='Name'
                    id='name'
                    name='name'
                    value={formState.name}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 20) {
                        dispatchForm({
                          type: "UPDATE_FIELD",
                          field: "name",
                          value,
                        });
                      }
                    }}
                    required
                  />

                  <InputField
                    label='Email'
                    id='email'
                    name='email'
                    type='email'
                    value={formState.email}
                    onChange={(e) => {
                      const { value } = e.target;

                      // Allow typing while enforcing character limit
                      if (value.length <= 25) {
                        dispatchForm({
                          type: "UPDATE_FIELD",
                          field: "email",
                          value,
                        });
                      }
                    }}
                    onBlur={() => {
                      // Validate email format on blur (when input loses focus)
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (
                        formState.email &&
                        !emailRegex.test(formState.email)
                      ) {
                        alert("Please enter a valid email address.");
                      }
                    }}
                    required
                  />

                  <div className='mb-2'>
                    <label htmlFor='phone' className='form-label small'>
                      Phone
                    </label>
                    <div className='d-flex'>
                      <select
                        id='countryCode'
                        className='form-select form-select-sm w-auto me-2'
                        value={formState.countryCode}
                        onChange={(e) =>
                          dispatchForm({
                            type: "UPDATE_FIELD",
                            field: "countryCode",
                            value: e.target.value,
                          })
                        }
                      >
                        <option value='+966'>+966</option>
                        <option value='+971'>+971</option>
                        <option value='+973'>+973</option>
                        <option value='+974'>+974</option>
                        <option value='+965'>+965</option>
                        <option value='+968'>+968</option>
                      </select>
                      <input
                        type='text'
                        id='phone'
                        name='phone'
                        className='form-control form-control-sm flex-grow-1'
                        value={formState.phone}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                          if (
                            value.length <= 9 &&
                            (value === "" || value[0] !== "0")
                          ) {
                            dispatchForm({
                              type: "UPDATE_FIELD",
                              field: "phone",
                              value,
                            });
                          }
                        }}
                        required
                      />
                    </div>
                  </div>

                  <InputField
                    label='CR Number'
                    id='crNumber'
                    name='crNumber'
                    value={formState.crNumber}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                      if (value.length <= 10) {
                        dispatchForm({
                          type: "UPDATE_FIELD",
                          field: "crNumber",
                          value,
                        });
                      }
                    }}
                    required
                  />
                </div>

                {/* Right Column */}
                <div className='col-md-6'>
                  <InputField
                    label='Address'
                    id='address'
                    name='address'
                    value={formState.address}
                    onChange={(e) =>
                      dispatchForm({
                        type: "UPDATE_FIELD",
                        field: "address",
                        value: e.target.value,
                      })
                    }
                    required
                  />
                  <InputField
                    label='City'
                    id='city'
                    name='city'
                    value={formState.city}
                    onChange={(e) =>
                      dispatchForm({
                        type: "UPDATE_FIELD",
                        field: "city",
                        value: e.target.value,
                      })
                    }
                    required
                  />
                  <InputField
                    label='Region'
                    id='region'
                    name='region'
                    value={formState.region}
                    onChange={(e) =>
                      dispatchForm({
                        type: "UPDATE_FIELD",
                        field: "region",
                        value: e.target.value,
                      })
                    }
                    required
                  />
                  <InputField
                    label='Other Cities Served'
                    id='otherCities'
                    name='otherCities'
                    value={formState.otherCities || ""}
                    onChange={(e) =>
                      dispatchForm({
                        type: "UPDATE_FIELD",
                        field: "otherCities",
                        value: e.target.value,
                      })
                    }
                  />
                  <div className='mb-2'>
                    <label className='form-label small'>Delivery Type:</label>
                    <div>
                      <label className='me-3'>
                        <input
                          type='radio'
                          name='deliveryType'
                          value='pickup' // Backend-compliant value
                          checked={formState.deliveryType === "pickup"}
                          onChange={(e) =>
                            dispatchForm({
                              type: "UPDATE_FIELD",
                              field: "deliveryType",
                              value: e.target.value,
                            })
                          }
                        />{" "}
                        Own Delivery {/* User-friendly label */}
                      </label>
                      <label className='me-3'>
                        <input
                          type='radio'
                          name='deliveryType'
                          value='delivery' // Backend-compliant value
                          checked={formState.deliveryType === "delivery"}
                          onChange={(e) =>
                            dispatchForm({
                              type: "UPDATE_FIELD",
                              field: "deliveryType",
                              value: e.target.value,
                            })
                          }
                        />{" "}
                        Outside Delivery {/* User-friendly label */}
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='deliveryType'
                          value='both' // Backend-compliant value
                          checked={formState.deliveryType === "both"}
                          onChange={(e) =>
                            dispatchForm({
                              type: "UPDATE_FIELD",
                              field: "deliveryType",
                              value: e.target.value,
                            })
                          }
                        />{" "}
                        Both {/* User-friendly label */}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button type='submit' className='btn btn-primary btn-sm mt-2'>
                {formState.id ? "Save Changes" : "Save Supplier"}
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Edit Supplier Modal */}
      {showEditModal && (
        <div className='modal fade show d-block' tabIndex='-1' role='dialog'>
          <div
            className='modal-dialog modal-dialog-centered modal-lg'
            role='document'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Edit Supplier</h5>
                <button
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                  onClick={() => setShowEditModal(false)}
                ></button>
              </div>
              <div className='modal-body'>
                <form>
                  <div className='row g-3'>
                    {/* Left Column */}
                    <div className='col-md-6'>
                      <InputField
                        label='Name'
                        id='edit-name'
                        name='name'
                        value={editFormData.name}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length <= 20) {
                            setEditFormData({ ...editFormData, name: value });
                          }
                        }}
                        required
                      />
                      <InputField
                        label='Email'
                        id='edit-email'
                        name='email'
                        value={editFormData.email}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (value.length <= 25) {
                            setEditFormData({ ...editFormData, email: value });
                          }
                        }}
                        onBlur={() => {
                          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          if (
                            editFormData.email &&
                            !emailRegex.test(editFormData.email)
                          ) {
                            alert("Invalid email address.");
                          }
                        }}
                        required
                      />
                      <InputField
                        label='Phone'
                        id='edit-phone'
                        name='phone'
                        value={editFormData.phone}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, ""); // Numeric only
                          if (
                            value.length <= 9 &&
                            (value === "" || value[0] !== "0")
                          ) {
                            setEditFormData({ ...editFormData, phone: value });
                          }
                        }}
                        required
                      />
                      <InputField
                        label='CR Number'
                        id='edit-crNumber'
                        name='crNumber'
                        value={editFormData.crNumber}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, ""); // Numeric only
                          if (value.length <= 10) {
                            setEditFormData({
                              ...editFormData,
                              crNumber: value,
                            });
                          }
                        }}
                        required
                      />
                    </div>

                    {/* Right Column */}
                    <div className='col-md-6'>
                      <InputField
                        label='Address'
                        id='edit-address'
                        name='address'
                        value={editFormData.address}
                        onChange={(e) =>
                          setEditFormData({
                            ...editFormData,
                            address: e.target.value,
                          })
                        }
                        required
                      />
                      <InputField
                        label='City'
                        id='edit-city'
                        name='city'
                        value={editFormData.city}
                        onChange={(e) =>
                          setEditFormData({
                            ...editFormData,
                            city: e.target.value,
                          })
                        }
                        required
                      />
                      <InputField
                        label='Region'
                        id='edit-region'
                        name='region'
                        value={editFormData.region}
                        onChange={(e) =>
                          setEditFormData({
                            ...editFormData,
                            region: e.target.value,
                          })
                        }
                        required
                      />
                      <InputField
                        label='Other Cities Served'
                        id='edit-otherCities'
                        name='otherCities'
                        value={editFormData.otherCities || ""}
                        onChange={(e) =>
                          setEditFormData({
                            ...editFormData,
                            otherCities: e.target.value,
                          })
                        }
                      />
                      <div className='mb-2'>
                        <label className='form-label small'>
                          Delivery Type:
                        </label>
                        <div>
                          <label className='me-3'>
                            <input
                              type='radio'
                              name='edit-deliveryType'
                              value='pickup'
                              checked={editFormData.deliveryType === "pickup"}
                              onChange={(e) =>
                                setEditFormData({
                                  ...editFormData,
                                  deliveryType: e.target.value,
                                })
                              }
                            />{" "}
                            Own Delivery
                          </label>
                          <label className='me-3'>
                            <input
                              type='radio'
                              name='edit-deliveryType'
                              value='delivery'
                              checked={editFormData.deliveryType === "delivery"}
                              onChange={(e) =>
                                setEditFormData({
                                  ...editFormData,
                                  deliveryType: e.target.value,
                                })
                              }
                            />{" "}
                            Outside Delivery
                          </label>
                          <label>
                            <input
                              type='radio'
                              name='edit-deliveryType'
                              value='both'
                              checked={editFormData.deliveryType === "both"}
                              onChange={(e) =>
                                setEditFormData({
                                  ...editFormData,
                                  deliveryType: e.target.value,
                                })
                              }
                            />{" "}
                            Both
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </button>
                <button
                  type='button'
                  className='btn btn-success'
                  onClick={handleSaveEdit}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {!loading && suppliers.length > 0 ? (
        <div className='table-responsive'>
          <table className='table table-striped table-hover'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {suppliers.map((supplier) => (
                <tr key={supplier.id}>
                  <td>{supplier.name}</td>
                  <td>{supplier.email}</td>
                  <td>{supplier.phone}</td>
                  <td>
                    <button
                      className='btn btn-outline-secondary btn-sm me-2'
                      onClick={() => handleEdit(supplier)}
                    >
                      Edit
                    </button>
                    <button
                      className='btn btn-danger btn-sm'
                      onClick={() => handleDelete(supplier.id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !loading && <p className='text-center'>No suppliers found.</p>
      )}

      {loading && (
        <div className='text-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Suppliers;
