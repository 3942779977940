import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { db } from "../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // Importing UUID generator
import logo from "../assets/logo.svg";
import Notification from "../components/shared/Notification";

const BuyerRegistration = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });
  const navigate = useNavigate();

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      showNotification(
        "Authentication Required",
        "User is not authenticated. Please log in."
      );
      navigate("/BuyerSupplierLogin"); // Redirect to login if not authenticated
      return;
    }

    // Retrieve phone number from localStorage
    const fullPhoneNumber = localStorage.getItem("userPhone");
    if (!fullPhoneNumber) {
      showNotification("Error", "Phone number not found. Please log in again.");
      navigate("/BuyerSupplierLogin"); // Redirect if phone number is missing
      return;
    }

    const uid = user.uid || uuidv4(); // Use UID from Firebase or generate UUID
    const role = "buyer"; // Set user role as buyer

    try {
      // Use UID as the document ID and add user data to Firestore
      await setDoc(
        doc(db, "users", uid),
        {
          role,
          name,
          email,
          phone: fullPhoneNumber,
          buyerId: uid, // Add buyerId field
        },
        { merge: true }
      );

      // Store token or a flag to indicate successful registration
      const token = await user.getIdToken(); // Get token for later authentication
      localStorage.setItem("authToken", token); // Store token in localStorage

      showNotification(
        "Success",
        "Buyer registered successfully! Redirecting to the home page."
      );
      navigate("/"); // Redirect to login page after successful registration

      // Clear form fields
      setName("");
      setEmail("");
    } catch (error) {
      console.error("Error adding buyer to Firestore:", error);
      showNotification("Error", "Failed to register buyer. Please try again.");
    }
  };

  return (
    <Container
      fluid
      className='d-flex flex-column align-items-center justify-content-center'
      style={{ minHeight: "100vh", backgroundColor: "#f7f7f7" }}
    >
      <div className='text-center mb-4 d-flex flex-column align-items-center'>
        <img
          src={logo}
          alt='Logo'
          style={{ width: "80px", marginBottom: "10px" }}
        />
        <h2 style={{ color: "#2d6a4f", fontWeight: "bold" }}>
          Buyer Registration
        </h2>
      </div>

      <Form
        onSubmit={handleSubmit}
        className='p-4 border rounded shadow-sm bg-white'
        style={{
          width: "100%",
          maxWidth: "350px",
          borderRadius: "8px",
          margin: "0 auto",
        }}
      >
        {/* Name Field */}
        <Form.Group controlId='formName' className='mb-3'>
          <Form.Control
            type='text'
            placeholder='Enter your name'
            value={name}
            onChange={(e) => {
              const input = e.target.value;
              // Allow only letters and spaces, limit to 15 characters
              if (/^[a-zA-Z\s]*$/.test(input) && input.length <= 15) {
                setName(input);
              } else {
                showNotification(
                  "Name can only contain letters and must be 15 characters or fewer."
                );
              }
            }}
            required
          />
        </Form.Group>

        {/* Email */}
        <Form.Group controlId='formEmail' className='mb-3'>
          <Form.Control
            type='email'
            placeholder='Enter your email (optional)'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        {/* Submit Button */}
        <Button
          variant='success'
          type='submit'
          className='w-100'
          style={{ backgroundColor: "#2d6a4f", borderColor: "#2d6a4f" }}
        >
          Continue
        </Button>
      </Form>

      {/* Notification Component */}
      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={1000} // Auto-close after 3 seconds
      />
    </Container>
  );
};

export default BuyerRegistration;
