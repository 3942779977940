import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/config";

const EditProduct = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [editingField, setEditingField] = useState(null); // Track which field is being edited
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productRef = doc(db, "products", productId);
        const productSnap = await getDoc(productRef);

        if (productSnap.exists()) {
          const data = productSnap.data();
          setProduct(data);
          setFormData(data);
        } else {
          console.error("Product not found.");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleArrayChange = (field, index, value) => {
    setFormData((prevState) => {
      const updatedArray = [...prevState[field]];
      updatedArray[index] = value;
      return {
        ...prevState,
        [field]: updatedArray,
      };
    });
  };

  const addToArrayField = (field, newItem = "") => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: [...(prevState[field] || []), newItem],
    }));
  };

  const removeFromArrayField = (field, index) => {
    setFormData((prevState) => {
      const updatedArray = [...prevState[field]];
      updatedArray.splice(index, 1);
      return {
        ...prevState,
        [field]: updatedArray,
      };
    });
  };

  const handleSave = async () => {
    try {
      const productRef = doc(db, "products", productId);
      await updateDoc(productRef, formData);
      alert("Product updated successfully!");
      setEditingField(null); // Exit editing mode
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Failed to update product.");
    }
  };

  const handleCancel = () => {
    setEditingField(null);
  };

  if (loading) {
    return <div>Loading product details...</div>;
  }

  if (!product) {
    return <div>Product not found.</div>;
  }

  return (
    <div className='container my-5'>
      <h2>Edit Product</h2>

      {/* Product Name */}
      <div className='mb-3'>
        <label className='form-label'>Product Name</label>
        {editingField === "productName" ? (
          <div className='d-flex align-items-center'>
            <input
              type='text'
              name='productName'
              value={formData.productName || ""}
              onChange={handleChange}
              className='form-control me-2'
            />
            <button
              type='button'
              className='btn btn-success btn-sm me-2'
              onClick={handleSave}
            >
              Save
            </button>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <span>{formData.productName || "N/A"}</span>
            <button
              type='button'
              className='btn btn-link btn-sm ms-2 text-danger'
              onClick={() => setEditingField("productName")}
            >
              Edit
            </button>
          </div>
        )}
      </div>

      {/* Category */}
      <div className='mb-3'>
        <label className='form-label'>Category</label>
        {editingField === "category" ? (
          <div className='d-flex align-items-center'>
            <input
              type='text'
              name='category'
              value={formData.category || ""}
              onChange={handleChange}
              className='form-control me-2'
            />
            <button
              type='button'
              className='btn btn-success btn-sm me-2'
              onClick={handleSave}
            >
              Save
            </button>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <span>{formData.category || "N/A"}</span>
            <button
              type='button'
              className='btn btn-link btn-sm ms-2 text-danger'
              onClick={() => setEditingField("category")}
            >
              Edit
            </button>
          </div>
        )}
      </div>

      {/* Description */}
      <div className='mb-3'>
        <label className='form-label'>Description</label>
        {editingField === "description" ? (
          <div className='d-flex align-items-center'>
            <textarea
              name='description'
              value={formData.description || ""}
              onChange={handleChange}
              className='form-control me-2'
            />
            <button
              type='button'
              className='btn btn-success btn-sm me-2'
              onClick={handleSave}
            >
              Save
            </button>
            <button
              type='button'
              className='btn btn-secondary btn-sm'
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <span>{formData.description || "N/A"}</span>
            <button
              type='button'
              className='btn btn-link btn-sm ms-2 text-danger'
              onClick={() => setEditingField("description")}
            >
              Edit
            </button>
          </div>
        )}
      </div>

      {/* Colors */}
      <div className='mb-3'>
        <label className='form-label'>Colors</label>
        {formData.colors?.map((color, index) => (
          <div key={index} className='d-flex align-items-center mb-2'>
            {editingField === `color-${index}` ? (
              <div className='d-flex align-items-center'>
                <input
                  type='text'
                  value={color}
                  onChange={(e) =>
                    handleArrayChange("colors", index, e.target.value)
                  }
                  className='form-control me-2'
                />
                <button
                  type='button'
                  className='btn btn-success btn-sm me-2'
                  onClick={() => setEditingField(null)}
                >
                  Save
                </button>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className='d-flex align-items-center'>
                <span>{color || "N/A"}</span>
                <button
                  type='button'
                  className='btn btn-link btn-sm ms-2 text-danger'
                  onClick={() => setEditingField(`color-${index}`)}
                >
                  Edit
                </button>
              </div>
            )}
            <button
              type='button'
              className='btn btn-danger btn-sm ms-2'
              onClick={() => removeFromArrayField("colors", index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type='button'
          className='btn btn-success btn-sm'
          onClick={() => addToArrayField("colors")}
        >
          Add Color
        </button>
      </div>

      {/* Sizes */}
      <div className='mb-3'>
        <label className='form-label'>Sizes</label>
        {formData.sizes?.map((size, index) => (
          <div key={index} className='d-flex align-items-center mb-2'>
            {editingField === `size-${index}` ? (
              <div className='d-flex align-items-center'>
                <input
                  type='text'
                  value={size}
                  onChange={(e) =>
                    handleArrayChange("sizes", index, e.target.value)
                  }
                  className='form-control me-2'
                />
                <button
                  type='button'
                  className='btn btn-success btn-sm me-2'
                  onClick={() => setEditingField(null)}
                >
                  Save
                </button>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className='d-flex align-items-center'>
                <span>{size || "N/A"}</span>
                <button
                  type='button'
                  className='btn btn-link btn-sm ms-2 text-danger'
                  onClick={() => setEditingField(`size-${index}`)}
                >
                  Edit
                </button>
              </div>
            )}
            <button
              type='button'
              className='btn btn-danger btn-sm ms-2'
              onClick={() => removeFromArrayField("sizes", index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type='button'
          className='btn btn-success btn-sm'
          onClick={() => addToArrayField("sizes")}
        >
          Add Size
        </button>
      </div>

      {/* Price Ranges */}
      <div className='mb-3'>
        <label className='form-label'>Price Ranges</label>
        {formData.priceRanges?.map((range, index) => (
          <div key={index} className='mb-2'>
            {editingField === `priceRange-${index}` ? (
              <div className='row mb-2'>
                <div className='col'>
                  <input
                    type='number'
                    placeholder='Min Qty'
                    value={range.minQty || ""}
                    onChange={(e) =>
                      handleArrayChange("priceRanges", index, {
                        ...range,
                        minQty: e.target.value,
                      })
                    }
                    className='form-control'
                  />
                </div>
                <div className='col'>
                  <input
                    type='number'
                    placeholder='Max Qty'
                    value={range.maxQty || ""}
                    onChange={(e) =>
                      handleArrayChange("priceRanges", index, {
                        ...range,
                        maxQty: e.target.value,
                      })
                    }
                    className='form-control'
                  />
                </div>
                <div className='col'>
                  <input
                    type='number'
                    placeholder='Price'
                    value={range.price || ""}
                    onChange={(e) =>
                      handleArrayChange("priceRanges", index, {
                        ...range,
                        price: e.target.value,
                      })
                    }
                    className='form-control'
                  />
                </div>
                <div className='col'>
                  <button
                    type='button'
                    className='btn btn-success btn-sm me-2'
                    onClick={() => setEditingField(null)}
                  >
                    Save
                  </button>
                  <button
                    type='button'
                    className='btn btn-secondary btn-sm'
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className='d-flex align-items-center'>
                <span>
                  Min: {range.minQty || "N/A"}, Max: {range.maxQty || "N/A"},
                  Price: ${range.price || "N/A"}
                </span>
                <button
                  type='button'
                  className='btn btn-link btn-sm ms-2 text-danger'
                  onClick={() => setEditingField(`priceRange-${index}`)}
                >
                  Edit
                </button>
              </div>
            )}
            <button
              type='button'
              className='btn btn-danger btn-sm'
              onClick={() => removeFromArrayField("priceRanges", index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type='button'
          className='btn btn-success btn-sm'
          onClick={() =>
            addToArrayField("priceRanges", {
              minQty: "",
              maxQty: "",
              price: "",
            })
          }
        >
          Add Price Range
        </button>
      </div>

      <button
        type='button'
        className='btn btn-secondary btn-sm'
        onClick={() => navigate("/edit-products")}
      >
        Back to Products
      </button>
    </div>
  );
};

export default EditProduct;
