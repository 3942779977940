import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/config"; // Import Firebase configuration

const CategoryDetails = () => {
  const { id } = useParams();
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  // Static categories and subcategories
  const categories = {
    1: {
      name: "Construction",
      subcategories: [
        { name: "Sand", image: "https://via.placeholder.com/150?text=Sand" },
        {
          name: "Cement",
          image: "https://via.placeholder.com/150?text=Cement",
        },
        { name: "Wood", image: "https://via.placeholder.com/150?text=Wood" },
        {
          name: "Bricks",
          image: "https://via.placeholder.com/150?text=Bricks",
        },
      ],
    },
    2: {
      name: "Plastic & Papers",
      subcategories: [
        {
          name: "Paper Cups",
          image: "https://via.placeholder.com/150?text=Paper+Cups",
        },
        {
          name: "Plastic Cups",
          image: "https://via.placeholder.com/150?text=Plastic+Cups",
        },
        {
          name: "Plastic Packaging",
          image: "https://via.placeholder.com/150?text=Plastic+Packaging",
        },
        {
          name: "White Papers",
          image: "https://via.placeholder.com/150?text=White+Papers",
        },
      ],
    },
    3: {
      name: "Equipment",
      subcategories: [
        {
          name: "Drilling Equipment",
          image: "https://via.placeholder.com/150?text=Drilling+Equipment",
        },
        {
          name: "Transportation Equipment",
          image:
            "https://via.placeholder.com/150?text=Transportation+Equipment",
        },
        {
          name: "Coating Equipment",
          image: "https://via.placeholder.com/150?text=Coating+Equipment",
        },
        {
          name: "Construction Equipment",
          image: "https://via.placeholder.com/150?text=Construction+Equipment",
        },
      ],
    },
    4: {
      name: "Saudi Manufactured Products",
      subcategories: [
        {
          name: "Sub Category 1",
          image: "https://via.placeholder.com/150?text=Furniture",
        },
        {
          name: "Sub Category 2",
          image: "https://via.placeholder.com/150?text=Electronics",
        },
        {
          name: "Sub Category 3",
          image: "https://via.placeholder.com/150?text=Textiles",
        },
        {
          name: "Sub Category 4",
          image: "https://via.placeholder.com/150?text=Industrial+Goods",
        },
      ],
    },
  };

  const subcategories = categories[id]?.subcategories || [];

  // Fetch products from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      if (!selectedSubcategory) return;
      setLoading(true);
      try {
        const q = query(
          collection(db, "products"),
          where("subCategory", "==", selectedSubcategory)
        );
        const querySnapshot = await getDocs(q);
        const productList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productList);
      } catch (error) {
        console.error("Error fetching products: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [selectedSubcategory]);

  const handleSubcategoryClick = (subcategoryName) => {
    setSelectedSubcategory(subcategoryName);
  };

  return (
    <div className='container my-5'>
      <h1 className='text-center mb-4' style={{ color: "#2c6449" }}>
        {categories[id]?.name || "Category"}
      </h1>
      <p
        className='text-center mb-5'
        style={{ fontSize: "18px", color: "#555" }}
      >
        Explore the best products under{" "}
        {categories[id]?.name || "this category"}.
      </p>

      {/* Subcategory Cards */}
      <div className='row'>
        {subcategories.map((sub, index) => (
          <div className='col-md-3 col-sm-6 mb-4' key={index}>
            <div
              className='card position-relative'
              style={{
                borderRadius: "10px",
                overflow: "hidden",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                height: "250px",
                cursor: "pointer",
              }}
              onClick={() => handleSubcategoryClick(sub.name)}
            >
              <img
                src={sub.image}
                alt={sub.name}
                style={{
                  objectFit: "cover",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              />
              <div
                className='overlay d-flex flex-column justify-content-center align-items-center'
                style={{
                  background: "rgba(44, 100, 73, 0.8)",
                  color: "#fff",
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  textAlign: "center",
                }}
              >
                <h5
                  className='card-title'
                  style={{
                    fontWeight: "600",
                    marginBottom: "10px",
                  }}
                >
                  {sub.name}
                </h5>
                <p
                  className='card-text'
                  style={{
                    fontSize: "14px",
                    marginBottom: "15px",
                  }}
                >
                  Discover more about {sub.name}.
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Products Grid */}
      {selectedSubcategory && (
        <div className='mt-5'>
          <h2 className='text-center mb-4' style={{ color: "#2c6449" }}>
            Products under {selectedSubcategory}
          </h2>
          {loading ? (
            <p style={{ textAlign: "center" }}>Loading products...</p>
          ) : products.length === 0 ? (
            <p style={{ textAlign: "center" }}>No products found.</p>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              {products.map((product) => {
                const priceRanges = product.priceRanges || [];
                const prices = priceRanges.map((range) => range.price);
                const lowestPrice = prices.length ? Math.min(...prices) : "N/A";
                const highestPrice = prices.length
                  ? Math.max(...prices)
                  : "N/A";
                const minOrder = priceRanges[0]?.minQty || "N/A";
                const mainImage =
                  product.mainImageUrl || "https://via.placeholder.com/300";
                const category = product.category || "Uncategorized";

                return (
                  <div
                    key={product.id}
                    style={{
                      border: "1px solid #ddd",
                      borderRadius: "10px",
                      overflow: "hidden",
                      background: "#fff",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        height: "200px",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={mainImage}
                        alt={product.productName}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div style={{ padding: "15px" }}>
                      <p
                        style={{
                          fontWeight: "500",
                          margin: "3px 0",
                          fontSize: "12px",
                          color: "#555",
                        }}
                      >
                        {category}
                      </p>
                      <h5 style={{ color: "#2c6449", fontWeight: "600" }}>
                        {product.productName}
                      </h5>
                      <p style={{ fontSize: "14px", color: "#666" }}>
                        ${lowestPrice} - ${highestPrice}
                      </p>
                      <p style={{ fontSize: "12px", color: "#888" }}>
                        Min. Order: {minOrder}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CategoryDetails;
