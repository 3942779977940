import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ShoppingCart, User } from "react-feather"; // Feather icons
import logo from "../../assets/logo.svg";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext
import { useCart } from "../../context/CartContext"; // Import CartContext

const Navbar = () => {
  const [currentLocation, setCurrentLocation] = useState("Detecting...");
  const [scrollDirection, setScrollDirection] = useState(null); // Track scroll direction
  const [isBottomBarVisible, setIsBottomBarVisible] = useState(false); // Visibility of bottom bar
  const [isSmallScreen, setIsSmallScreen] = useState(false); // Track screen size
  const lastScrollY = useRef(0); // Persist lastScrollY value across renders
  const { userData, logout } = useAuth(); // Destructure userData and logout from AuthContext
  const { cartItems, clearCart } = useCart(); // Destructure cart actions
  const navigate = useNavigate(); // Navigation hook
  const location = useLocation(); // Get current location

  // Simulate location detection
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation(
          `Lat: ${latitude.toFixed(2)}, Lon: ${longitude.toFixed(2)}`
        );
      },
      () => {
        setCurrentLocation("Unable to detect location");
      }
    );
  }, []);

  // Track screen size to determine behavior
  useEffect(() => {
    const updateScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Small screen for width <= 768px
    };

    updateScreenSize(); // Initial check
    window.addEventListener("resize", updateScreenSize);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  // Scroll event handling for large screens
  useEffect(() => {
    if (isSmallScreen) return; // Skip scroll handling for small screens

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY.current) {
        // Scrolling down
        setScrollDirection("down");
        setIsBottomBarVisible(true);
      } else {
        // Scrolling up
        setScrollDirection("up");
        setIsBottomBarVisible(false);
      }

      lastScrollY.current = currentScrollY > 0 ? currentScrollY : 0; // Avoid negative scroll values
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSmallScreen]);

  // Handle Logout
  const handleLogout = async () => {
    try {
      clearCart(); // Clear the cart on logout
      await logout(); // Perform logout logic
      navigate("/user-login"); // Redirect to login page
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  // Handle User Icon Click
  const handleUserIconClick = () => {
    if (userData) {
      const { role } = userData;
      const currentPath = location.pathname; // Use location hook

      if (role === "buyer" && currentPath !== "/buyer-dashboard") {
        navigate("/buyer-dashboard");
      } else if (role === "supplier" && currentPath !== "/supplier-dashboard") {
        navigate("/supplier-dashboard");
      } else if (role === "admin" && currentPath !== "/admin-dashboard") {
        navigate("/admin-dashboard");
      } else {
        console.log(
          "User is already on the correct dashboard page or role is unhandled:",
          role
        );
      }
    } else {
      if (location.pathname !== "/user-login") {
        navigate("/user-login");
      }
    }
  };

  return (
    <>
      {/* Top Bar */}
      <div
        className={`top-bar bg-light py-2 border-bottom ${
          !isSmallScreen && scrollDirection === "down"
            ? "slide-out"
            : "slide-in"
        }`}
      >
        <div className='container d-flex justify-content-between align-items-center'>
          {/* Left Side: Language, Currency, and Become a Supplier */}
          <div className='d-flex align-items-center gap-3'>
            {/* Language Selector */}
            <div className='dropdown'>
              <button
                className='btn btn-link dropdown-toggle'
                type='button'
                id='languageDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                style={{ color: "#2c6449", textDecoration: "none" }}
              >
                English
              </button>
              <ul className='dropdown-menu' aria-labelledby='languageDropdown'>
                <li>
                  <button className='dropdown-item'>English</button>
                </li>
                <li>
                  <button className='dropdown-item'>Arabic</button>
                </li>
              </ul>
            </div>

            {/* Currency Selector */}
            <div className='dropdown'>
              <button
                className='btn btn-link dropdown-toggle'
                type='button'
                id='currencyDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                style={{ color: "#2c6449", textDecoration: "none" }}
              >
                USD
              </button>
              <ul className='dropdown-menu' aria-labelledby='currencyDropdown'>
                <li>
                  <button className='dropdown-item'>USD</button>
                </li>
                <li>
                  <button className='dropdown-item'>SAR</button>
                </li>
              </ul>
            </div>

            {/* "Become a Supplier" */}
            <Link
              to='/become-supplier'
              className='text-decoration-none'
              style={{ color: "#2c6449" }}
            >
              Become a Supplier
            </Link>
          </div>

          {/* Right Side: Location Detector */}
          <div className='text-muted small'>{currentLocation}</div>
        </div>
      </div>

      {/* Navbar */}
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-light ${
          !isSmallScreen && scrollDirection === "down"
            ? "slide-out"
            : "slide-in"
        }`}
      >
        <div className='container'>
          {/* Logo */}
          <Link className='navbar-brand d-flex align-items-center' to='/'>
            <img
              src={logo}
              alt='Marsos Logo'
              style={{ height: "120px", marginRight: "10px" }}
            />
          </Link>

          {/* Navbar Toggler */}
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          {/* Main Menu */}
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav mx-auto'>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to='/'
                  style={{ color: "#2c6449", textDecoration: "none" }}
                >
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to='/'
                  style={{ color: "#2c6449", textDecoration: "none" }}
                >
                  Featured Products
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to='/categories'
                  style={{ color: "#2c6449", textDecoration: "none" }}
                >
                  Categories
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  className='nav-link'
                  to='/'
                  style={{ color: "#2c6449", textDecoration: "none" }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Right Side: Search, Cart, User */}
          <div className='d-flex align-items-center gap-3'>
            {/* Search Bar */}
            <form className='d-flex' role='search'>
              <input
                className='form-control'
                type='search'
                placeholder='Search'
                aria-label='Search'
                style={{
                  borderRadius: "20px",
                  borderColor: "#2c6449",
                  maxWidth: "200px",
                }}
              />
            </form>

            <div className='d-flex align-items-center gap-3'>
              {/* Conditionally render Cart Icon if user is a buyer */}
              {userData?.role === "buyer" && (
                <Link to='/cart' style={{ color: "#2c6449" }}>
                  <ShoppingCart size={24} />
                  {cartItems.length > 0 && (
                    <span className='badge bg-danger'>{cartItems.length}</span>
                  )}
                </Link>
              )}
            </div>

            {/* User Icon with Dropdown */}
            <div className='dropdown'>
              <button
                className='btn btn-link dropdown-toggle d-flex align-items-center gap-2'
                id='userDropdown'
                type='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                style={{ color: "#2c6449", textDecoration: "none" }}
              >
                <User size={24} />
                {userData && (
                  <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                    {userData.name || "Unauthenticated User"}
                  </span>
                )}
              </button>
              <ul className='dropdown-menu' aria-labelledby='userDropdown'>
                {userData ? (
                  <>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={handleUserIconClick}
                      >
                        Dashboard
                      </button>
                    </li>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={handleLogout}
                        style={{ color: "red" }}
                      >
                        Logout
                      </button>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link className='dropdown-item' to='/user-login'>
                      Login / Register
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Bottom Bar */}
      {!isSmallScreen && (
        <div
          className={`bottom-bar bg-light py-3 border-bottom ${
            isBottomBarVisible ? "slide-in" : "slide-out"
          }`}
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 1030,
          }}
        >
          <div className='container d-flex justify-content-center align-items-center gap-4'>
            {/* Logo */}
            <Link to='/' className='d-flex align-items-center'>
              <img
                src={logo}
                alt='Marsos Logo'
                style={{ height: "80px", marginRight: "10px" }}
              />
            </Link>

            {/* Center Items */}
            <Link
              to='/become-supplier'
              className='text-decoration-none'
              style={{ color: "#2c6449" }}
            >
              Become a Supplier
            </Link>
            <Link
              to='/get-app'
              className='text-decoration-none'
              style={{ color: "#2c6449" }}
            >
              Get the App
            </Link>
            <Link
              to='/help-center'
              className='text-decoration-none'
              style={{ color: "#2c6449" }}
            >
              Help Center
            </Link>

            <form className='d-flex' role='search'>
              <input
                className='form-control'
                type='search'
                placeholder='Search'
                aria-label='Search'
                style={{
                  borderRadius: "20px",
                  borderColor: "#2c6449",
                  maxWidth: "200px",
                }}
              />
            </form>
            <div className='d-flex align-items-center gap-3'>
              {/* Conditionally render Cart Icon if user is a buyer */}
              {userData?.role === "buyer" && (
                <Link to='/cart' style={{ color: "#2c6449" }}>
                  <ShoppingCart size={24} />
                  {cartItems.length > 0 && (
                    <span className='badge bg-danger'>{cartItems.length}</span>
                  )}
                </Link>
              )}
            </div>

            {/* User Icon with Dropdown */}
            <div className='dropdown'>
              <button
                className='btn btn-link dropdown-toggle d-flex align-items-center gap-2'
                id='userDropdown'
                type='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
                style={{ color: "#2c6449", textDecoration: "none" }}
              >
                <User size={24} />
                {userData && (
                  <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                    {userData.name || "User"}
                  </span>
                )}
              </button>
              <ul className='dropdown-menu' aria-labelledby='userDropdown'>
                {userData ? (
                  <>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={handleUserIconClick}
                      >
                        Dashboard
                      </button>
                    </li>
                    <li>
                      <button
                        className='dropdown-item'
                        onClick={handleLogout}
                        style={{ color: "red" }}
                      >
                        Logout
                      </button>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link className='dropdown-item' to='/user-login'>
                      Login / Register
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
