import React, { useState } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import logo from "../assets/logo.svg";
import Notification from "../components/shared/Notification";
import { PrimaryButton } from "../components/shared/StyledComponents";

const AdminLogin = () => {
  const navigate = useNavigate();

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  // const handleLogin = async () => {
  //   const provider = new GoogleAuthProvider();
  //   const auth = getAuth();
  //   try {
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;

  //     const docRef = doc(db, "users", user.uid);
  //     const userDoc = await getDoc(docRef);

  //     if (userDoc.exists()) {
  //       const role = userDoc.data().role;

  //       if (role === "admin") {
  //         showNotification("Admin logged in successfully");
  //         navigate("/admin-dashboard");
  //       } else {
  //         showNotification("Access denied: You do not have admin privileges.");
  //         navigate("/");
  //       }
  //     } else {
  //       await setDoc(docRef, {
  //         uid: user.uid,
  //         name: user.displayName,
  //         email: user.email,
  //         role: "admin",
  //       });
  //       showNotification("Admin logged in successfully (role created)");
  //       navigate("/admin-dashboard");
  //     }
  //   } catch (error) {
  //     console.error("Error during login:", error.message);
  //     showNotification("Error during login:");
  //     alert("Login failed! Please try again.");
  //   }
  // };

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const docRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(docRef);

      let role = "guest"; // Default role if not found
      if (userDoc.exists()) {
        role = userDoc.data().role;

        if (role === "admin") {
          // Save user info to localStorage as a single object
          localStorage.setItem(
            "user",
            JSON.stringify({
              uid: user.uid,
              name: user.displayName,
              email: user.email,
              role: role,
            })
          );

          showNotification("Admin logged in successfully");
          navigate("/admin-dashboard");
        } else {
          showNotification("Access denied: You do not have admin privileges.");
          navigate("/");
        }
      } else {
        // Create a new user document in Firestore with default admin role
        await setDoc(docRef, {
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          role: "admin", // Default to admin for first-time login
        });

        // Save user info to localStorage as a single object
        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: user.uid,
            name: user.displayName,
            email: user.email,
            role: "admin",
          })
        );

        showNotification("Admin logged in successfully (role created)");
        navigate("/admin-dashboard");
      }
    } catch (error) {
      console.error("Error during login:", error.message);
      showNotification("Error during login:", error.message);
      alert("Login failed! Please try again.");
    }
  };

  return (
    <div
      className='d-flex flex-column align-items-center justify-content-center'
      style={{ height: "100vh", backgroundColor: "#f7f7f7" }}
    >
      <div className='text-center mb-4'>
        <img
          src={logo}
          alt='Logo'
          style={{ width: "80px", marginBottom: "20px" }}
        />
      </div>

      <div
        className='p-4 border rounded shadow-sm text-center'
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          maxWidth: "400px",
        }}
      >
        <h2 className='mb-3'>Admin Login</h2>
        <PrimaryButton className='btn btn-success w-100' onClick={handleLogin}>
          Sign in with Google
        </PrimaryButton>
      </div>

      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={3000}
      />
    </div>
  );
};

export default AdminLogin;
