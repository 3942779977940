import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Instagram, Linkedin, Twitter, Globe } from "react-feather";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#2c6449",
        color: "#fff",
        padding: "2rem 0",
      }}
    >
      <Container>
        <Row>
          {/* Contact Information */}
          <Col md={4}>
            <h5>Marsos eCommerce Platform</h5>
            <p>Powered by Marsos Development Team</p>
            <p>
              Kingdom of Saudi Arabia - Riyadh - Al Rayyan - Eastern Ring Road
              14211, KSA
            </p>
          </Col>

          {/* Site Map */}
          <Col md={3}>
            <ul className='list-unstyled'>
              <li>Home</li>
              <li>Featured Products</li>
              <li>Categories</li>
              <li>Contact</li>
            </ul>
          </Col>

          {/* Invest Links */}
          <Col md={3}>
            <ul className='list-unstyled'>
              <li>Become a Supplier</li>
              <li>Login as Employee</li>
              <li>Get the App</li>
              <li>Help Center</li>
            </ul>
          </Col>

          <Col md={2}>
            <ul className='list-unstyled'>
              <li>Privacy Policy</li>
              <li>Terms & Conditions</li>
              <li>Subscribe Newsletter</li>
            </ul>
          </Col>
        </Row>

        {/* Social Media Icons Section */}
        <Row className='mt-4 text-center'>
          <Col>
            <div>
              <a
                href='https://www.tiktok.com'
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: "#fff", fontSize: "1.5rem", margin: "0 10px" }}
              >
                <Globe />
              </a>
              <a
                href='https://www.instagram.com'
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: "#fff", fontSize: "1.5rem", margin: "0 10px" }}
              >
                <Instagram />
              </a>
              <a
                href='https://www.linkedin.com'
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: "#fff", fontSize: "1.5rem", margin: "0 10px" }}
              >
                <Linkedin />
              </a>
              <a
                href='https://twitter.com'
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: "#fff", fontSize: "1.5rem", margin: "0 10px" }}
              >
                <Twitter />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
