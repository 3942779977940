import React, { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const { userData } = useAuth();

  useEffect(() => {
    const fetchProducts = async () => {
      if (!userData) return;

      const productRef = collection(db, "products");
      const q = query(productRef, where("supplierId", "==", userData.uid));
      const querySnapshot = await getDocs(q);

      const productList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productList);
    };

    fetchProducts();
  }, [userData]);

  const handleEdit = (id) => {
    console.log("Edit product:", id);
  };

  const handleDelete = (id) => {
    console.log("Delete product:", id);
  };

  return (
    <div>
      <h3>Your Products</h3>
      <div className='d-flex flex-wrap'>
        {products.length > 0 ? (
          products.map((product) => (
            <div
              className='card'
              style={{ width: "18rem", margin: "10px" }}
              key={product.id}
            >
              {/* Display Thumbnail */}
              <img
                src={
                  product.images?.thumbnail || "https://via.placeholder.com/200"
                }
                className='card-img-top'
                alt={product.name || "Product Image"}
                style={{ height: "200px", objectFit: "cover" }}
              />
              <div className='card-body'>
                {/* Product Details */}
                <h5 className='card-title'>
                  {product.name || "Unnamed Product"}
                </h5>
                <p className='card-text'>
                  {product.description || "No description available."}
                </p>
                <p className='card-text'>
                  <strong>
                    ${product.price ? product.price.toFixed(2) : "N/A"}
                  </strong>
                </p>
                <div className='d-flex gap-2'>
                  <button
                    className='btn btn-warning btn-sm'
                    onClick={() => handleEdit(product.id)}
                  >
                    Edit
                  </button>
                  <button
                    className='btn btn-danger btn-sm'
                    onClick={() => handleDelete(product.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No products found. Add your first product!</p>
        )}
      </div>
    </div>
  );
};

export default ProductList;
