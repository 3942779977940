import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { db } from "../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Notification from "../components/shared/Notification";
import logo from "../assets/logo.svg";
import Autocomplete from "react-google-autocomplete";

const SupplierRegistration = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [crNumber, setCrNumber] = useState("");
  const [crLicense, setCrLicense] = useState(null); // CR License file
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [otherCitiesServed, setOtherCitiesServed] = useState("");
  const [deliveryOption, setDeliveryOption] = useState("own");
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState({
    title: "",
    message: "",
  });

  const navigate = useNavigate();

  const showNotification = (title, message) => {
    setNotificationContent({ title, message });
    setIsNotificationOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      showNotification(
        "Authentication Required",
        "User is not authenticated. Please log in."
      );
      navigate("/login");
      return;
    }

    if (!companyLogo) {
      showNotification("Please upload your company logo before proceeding.");
      return;
    }

    // Retrieve phone number from localStorage
    const fullPhoneNumber = localStorage.getItem("userPhone");
    if (!fullPhoneNumber) {
      showNotification("Error", "Phone number not found. Please log in again.");
      navigate("/login");
      return;
    }

    const uid = user.uid;
    const storage = getStorage();

    try {
      let companyLogoURL = null;
      let crLicenseURL = null;

      // Upload company logo if selected
      if (companyLogo) {
        const logoRef = ref(storage, `logos/${uid}-${companyLogo.name}`);
        const logoSnapshot = await uploadBytes(logoRef, companyLogo);
        companyLogoURL = await getDownloadURL(logoSnapshot.ref);
      }

      // Upload CR license if selected
      if (crLicense) {
        const licenseRef = ref(storage, `licenses/${uid}-${crLicense.name}`);
        const licenseSnapshot = await uploadBytes(licenseRef, crLicense);
        crLicenseURL = await getDownloadURL(licenseSnapshot.ref);
      }

      // Save supplier data in the 'users' collection with URLs and supplierId field
      await setDoc(
        doc(db, "users", uid),
        {
          role: "supplier",
          name,
          email,
          phone: fullPhoneNumber, // Phone number from localStorage
          companyName,
          companyLogo: companyLogoURL,
          crNumber,
          crLicense: crLicenseURL, // Store the license URL in Firestore
          address,
          city,
          region,
          otherCitiesServed,
          deliveryOption,
          uid,
          supplierId: uid, // New supplierId field added here
        },
        { merge: true }
      );

      showNotification(
        "Success",
        "Supplier registered successfully! Redirecting to your dashboard."
      );
      navigate("/supplier-dashboard");

      // Clear form fields
      setName("");
      setEmail("");
      setCompanyName("");
      setCompanyLogo(null);
      setCrNumber("");
      setCrLicense(null);
      setAddress("");
      setCity("");
      setRegion("");
      setOtherCitiesServed("");
      setDeliveryOption("own");
    } catch (error) {
      console.error("Error registering supplier:", error);
      showNotification(
        "Error",
        "Failed to register supplier. Please try again."
      );
    }
  };

  const saudiCities = [
    "Riyadh",
    "Jeddah",
    "Mecca",
    "Medina",
    "Dammam",
    "Khobar",
    "Taif",
    "Tabuk",
    "Qatif",
    "Abha",
    "Khamis Mushait",
    "Al Khafji",
    "Hafar Al-Batin",
    "Al Qunfudhah",
    "Yanbu",
    "Najran",
    "Jizan",
    "Al Hasa",
    "Hail",
    "Al Baha",
    "Al Jubail",
  ];

  const saudiRegions = [
    "Riyadh Region",
    "Makkah Region",
    "Medina Region",
    "Eastern Province",
    "Asir Region",
    "Tabuk Region",
    "Hail Region",
    "Northern Borders Region",
    "Jazan Region",
    "Najran Region",
    "Al Bahah Region",
    "Al Jawf Region",
    "Qassim Region",
  ];

  return (
    <Container
      fluid
      className='d-flex flex-column align-items-center justify-content-center'
      style={{ minHeight: "100vh", backgroundColor: "#f7f7f7" }}
    >
      <div className='text-center mb-4 d-flex flex-column align-items-center'>
        <img
          src={logo}
          alt='Logo'
          style={{ width: "80px", marginBottom: "10px" }}
        />
        <h2 style={{ color: "#2d6a4f", fontWeight: "bold" }}>
          Supplier Registration
        </h2>
      </div>

      <Form
        onSubmit={handleSubmit}
        className='p-4 border rounded shadow-sm bg-white'
        style={{
          width: "100%",
          maxWidth: "400px",
          borderRadius: "8px",
          margin: "0 auto",
        }}
      >
        <Form.Group controlId='formName' className='mb-3'>
          <Form.Control
            type='text'
            placeholder='Enter your name'
            value={name}
            onChange={(e) => {
              const input = e.target.value;
              // Allow only letters and spaces, limit to 15 characters
              if (/^[a-zA-Z\s]*$/.test(input) && input.length <= 15) {
                setName(input);
              } else {
                showNotification(
                  "Name can only contain letters and must be 15 characters or fewer."
                );
              }
            }}
            required
          />
        </Form.Group>

        <Form.Group controlId='formEmail' className='mb-3'>
          <Form.Control
            type='email'
            placeholder='Enter your email (optional)'
            value={email}
            maxLength={15} // Restrict input to 15 characters
            onChange={(e) => setEmail(e.target.value)} // Allow typing freely
            onBlur={() => {
              if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
                showNotification("Please enter a valid email address.");
              }
            }}
          />
        </Form.Group>

        <Form.Group controlId='formCompanyName' className='mb-3'>
          <Form.Control
            type='text'
            placeholder='Enter your company name'
            value={companyName}
            onChange={(e) => {
              const input = e.target.value;
              // Allow only letters, spaces, and limit to 30 characters
              if (/^[a-zA-Z\s]*$/.test(input) && input.length <= 30) {
                setCompanyName(input);
              } else if (input.length > 30) {
                showNotification("Company name cannot exceed 30 characters.");
              } else {
                showNotification(
                  "Company name can only contain letters and spaces."
                );
              }
            }}
            required
          />
        </Form.Group>

        <Form.Group controlId='formCompanyLogo' className='mb-3'>
          <Form.Label>Upload Your Company Logo</Form.Label>
          <Form.Control
            type='file'
            onChange={(e) => setCompanyLogo(e.target.files[0])}
          />
        </Form.Group>

        <Form.Group controlId='formCrNumber' className='mb-3'>
          <Form.Control
            type='text'
            placeholder='Enter your CR number'
            value={crNumber}
            onChange={(e) => {
              const input = e.target.value;
              // Allow only digits and ensure it's at most 10 characters long
              if (/^\d*$/.test(input) && input.length <= 10) {
                setCrNumber(input);
              } else if (input.length > 10) {
                showNotification("CR number must be exactly 10 digits.");
              } else {
                showNotification("CR number can only contain numbers.");
              }
            }}
            required
          />
        </Form.Group>

        <Form.Group controlId='formAddress' className='mb-3'>
          <Form.Label>Enter your address</Form.Label>
          <Autocomplete
            apiKey='AIzaSyD92sPOOqCshhZW-rQdS71XohnOMRqOsG8'
            onPlaceSelected={(place) => {
              console.log("Selected Place: ", place);

              // Extract full formatted address
              setAddress(place.formatted_address || "");

              // Extract city (locality) or fallback to administrative_area_level_2
              const cityComponent = place.address_components.find(
                (comp) =>
                  comp.types.includes("locality") ||
                  comp.types.includes("administrative_area_level_2")
              );
              console.log("City Component: ", cityComponent);
              const cityMatch = saudiCities.find(
                (c) => c === cityComponent?.long_name
              );
              setCity(cityMatch || cityComponent?.long_name || "");

              // Extract region (administrative area level 1)
              const regionComponent = place.address_components.find((comp) =>
                comp.types.includes("administrative_area_level_1")
              );
              console.log("Region Component: ", regionComponent);
              const regionMatch = saudiRegions.find(
                (r) => r === regionComponent?.long_name
              );
              setRegion(regionMatch || regionComponent?.long_name || "");
            }}
            types={["address"]}
            placeholder='Enter your address'
            className='form-control'
          />
        </Form.Group>

        <Form.Group controlId='formCity' className='mb-3'>
          <Form.Label>Choose your city</Form.Label>
          <Form.Select
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          >
            <option value=''>Select your city</option>
            {saudiCities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId='formRegion' className='mb-3'>
          <Form.Label>Choose your region</Form.Label>
          <Form.Select
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            required
          >
            <option value=''>Select your region</option>
            {saudiRegions.map((region) => (
              <option key={region} value={region}>
                {region}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId='formOtherCitiesServed' className='mb-3'>
          <Form.Control
            type='text'
            placeholder='Enter other cities served'
            value={otherCitiesServed}
            onChange={(e) => setOtherCitiesServed(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId='formDeliveryOption' className='mb-3'>
          <div>
            <Form.Check
              type='radio'
              label='Own Delivery'
              name='deliveryOption'
              value='own'
              checked={deliveryOption === "own"}
              onChange={(e) => setDeliveryOption(e.target.value)}
            />
            <Form.Check
              type='radio'
              label='Outside Delivery'
              name='deliveryOption'
              value='outside'
              checked={deliveryOption === "outside"}
              onChange={(e) => setDeliveryOption(e.target.value)}
            />
          </div>
        </Form.Group>

        <Button
          variant='success'
          type='submit'
          className='w-100'
          style={{ backgroundColor: "#2d6a4f", borderColor: "#2d6a4f" }}
        >
          Continue
        </Button>
      </Form>

      <Notification
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        title={notificationContent.title}
        message={notificationContent.message}
        duration={300}
      />
    </Container>
  );
};

export default SupplierRegistration;
