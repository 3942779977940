import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import constructionImg from "../assets/construction.webp";
import plasticAndPapersImg from "../assets/plasticandpapers.webp";
import equipmentImg from "../assets/equipment.webp";

const CategoriesAndProductsPage = () => {
  const [products, setProducts] = useState([]); // State to hold all products
  const [loading, setLoading] = useState(true); // State for loading status
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered product list
  const [activeTab, setActiveTab] = useState("All"); // Default active tab
  const [isSmallScreen, setIsSmallScreen] = useState(false); // Track screen size

  const navigate = useNavigate();

  // Detect screen size for tabs or dropdown
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 768);
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch products from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "products"));
        const productList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productList);
        setFilteredProducts(productList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Apply filtering based on the active tab
  useEffect(() => {
    let filtered = [...products];
    if (activeTab === "All") {
      // Show all products
      filtered = products;
    } else if (activeTab === "Construction") {
      // Filter products by "Construction" category
      filtered = products.filter(
        (product) => product.category === "Construction"
      );
    } else if (activeTab === "Plastic & Papers") {
      // Filter products by "Plastic & Papers" category
      filtered = products.filter(
        (product) => product.category === "Plastic & Papers"
      );
    } else if (activeTab === "Equipment") {
      // Filter products by "Equipment" category
      filtered = products.filter((product) => product.category === "Equipment");
    } else if (activeTab === "Saudi Manufactured Products") {
      // Filter products by "Saudi Manufactured Products" category
      filtered = products.filter(
        (product) => product.category === "Saudi Manufactured Products"
      );
    }
    setFilteredProducts(filtered);
  }, [activeTab, products]);

  if (loading) {
    return <p style={{ textAlign: "center" }}>Loading...</p>;
  }

  return (
    <div>
      {/* Categories Section */}
      <div className='container my-5'>
        <h1 className='text-center mb-4' style={{ color: "#2c6449" }}>
          Categories
        </h1>
        <div className='row'>
          {[
            {
              id: 1,
              name: "Construction",
              image: constructionImg,
            },
            {
              id: 2,
              name: "Plastic & Papers",
              image: plasticAndPapersImg,
            },
            {
              id: 3,
              name: "Equipment",
              image: equipmentImg,
            },
            {
              id: 4,
              name: "Saudi Manufactured Products",
              image: "https://via.placeholder.com/300",
            },
          ].map((category) => (
            <div className='col-md-3 mb-4' key={category.id}>
              <div
                className='card position-relative'
                style={{
                  borderRadius: "10px",
                  overflow: "hidden",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "200px", // Adjusted height
                }}
              >
                <img
                  src={category.image}
                  alt={category.name}
                  className='card-img-top'
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                />
                {/* Overlay */}
                <div className='overlay'>
                  <h5 className='text-white text-center mb-3'>
                    {category.name}
                  </h5>
                  <Link
                    to={`/categories/${category.id}`}
                    className='btn btn-outline-light'
                  >
                    Explore
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Products Section */}
      <div className='container my-5'>
        <h2 className='text-center mb-4' style={{ color: "#2c6449" }}>
          Explore Products
        </h2>

        {/* Header Row */}
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          {/* Title */}
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "#2c6449",
              margin: 0,
            }}
          >
            Total {filteredProducts.length} Products
          </h2>

          {/* Tabs or Dropdown */}
          {isSmallScreen ? (
            <select
              value={activeTab}
              onChange={(e) => setActiveTab(e.target.value)}
              style={{
                padding: "10px",
                fontSize: "14px",
                border: "1px solid #ddd",
                borderRadius: "5px",
                background: "#fff",
                color: "#555",
              }}
            >
              {[
                "All",
                "Plastic & Papers",
                "Construction",
                "Equipment",
                "Saudi Manufactured Products",
              ].map((tab) => (
                <option key={tab} value={tab}>
                  {tab}
                </option>
              ))}
            </select>
          ) : (
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              {[
                "All",
                "Construction",
                "Plastic & Papers",
                "Equipment",
                "Saudi Manufactured Products",
              ].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  style={{
                    padding: "8px 15px",
                    background: activeTab === tab ? "#2c6449" : "transparent",
                    color: activeTab === tab ? "#fff" : "#555",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: activeTab === tab ? "bold" : "normal",
                  }}
                >
                  {tab}
                </button>
              ))}
            </div>
          )}
        </div>

        {/* Products Grid */}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {filteredProducts.map((product) => {
            const priceRanges = product.priceRanges || [];
            const prices = priceRanges.map((range) => range.price);
            const lowestPrice = prices.length ? Math.min(...prices) : "N/A";
            const highestPrice = prices.length ? Math.max(...prices) : "N/A";
            const mainImage =
              product.mainImageUrl || "https://via.placeholder.com/300";
            const category = product.category || "Uncategorized";

            return (
              <div
                key={product.id}
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  overflow: "hidden",
                  background: "#fff",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                {/* Product Image */}
                <div
                  style={{
                    height: "200px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={mainImage}
                    alt={product.productName || "Unnamed Product"}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>

                {/* Product Details */}
                <div style={{ padding: "15px" }}>
                  {/* Category */}
                  <p
                    style={{
                      fontWeight: "500",
                      margin: "3px 0",
                      fontSize: "12px",
                      color: "#555",
                    }}
                  >
                    {category}
                  </p>

                  {/* Product Name */}
                  <p
                    style={{
                      fontWeight: "600",
                      margin: "5px 0",
                      fontSize: "16px",
                      lineHeight: "1.2",
                    }}
                  >
                    {product.productName || "Unnamed Product"}
                  </p>

                  {/* Price Range */}
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      margin: "3px 0",
                      lineHeight: "1.2",
                    }}
                  >
                    ${lowestPrice} - ${highestPrice}
                  </p>

                  {/* Minimum Order */}
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#888",
                      margin: "3px 0",
                      lineHeight: "1.2",
                    }}
                  >
                    Min. order: {product.priceRanges[0]?.minQty || "N/A"}{" "}
                    piece(s)
                  </p>

                  {/* Main Location */}
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#666",
                      margin: "3px 0",
                      lineHeight: "1.2",
                    }}
                  >
                    Origin: {product.mainLocation || "Location not specified"}
                  </p>

                  {/* Action Button */}
                  <button
                    onClick={() => navigate(`/product/${product.id}`)}
                    style={{
                      padding: "8px 15px",
                      backgroundColor: "#2c6449",
                      color: "#fff",
                      border: "none",
                      borderRadius: "3px",
                      cursor: "pointer",
                      fontSize: "13px",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    View Details
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CategoriesAndProductsPage;
