import React, { useEffect, useState } from "react";
import { db, auth } from "../../firebase/config";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const BuyerDashboard = () => {
  const [buyerData, setBuyerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const [orders, setOrders] = useState([]); // Holds orders

  useEffect(() => {
    const fetchBuyerData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setBuyerData(docSnap.data());
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        console.error("Error fetching buyer data:", error);
      }
    };

    const fetchChats = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("User not authenticated.");
          return;
        }

        const chatsRef = collection(db, "chats");
        const chatsQuery = query(chatsRef, where("buyerId", "==", user.uid));
        const chatsSnapshot = await getDocs(chatsQuery);

        if (chatsSnapshot.empty) {
          setChats([]);
          return;
        }

        const chatList = await Promise.all(
          chatsSnapshot.docs.map(async (chatDoc) => {
            const chatData = chatDoc.data();

            try {
              // Fetch product details
              const productRef = doc(db, "products", chatData.productId);
              const productSnap = await getDoc(productRef);
              const productName = productSnap.exists()
                ? productSnap.data().productName
                : "Unnamed Product";

              // Fetch supplier details
              const supplierRef = doc(db, "users", chatData.supplierId);
              const supplierSnap = await getDoc(supplierRef);
              const supplierName = supplierSnap.exists()
                ? supplierSnap.data().companyName ||
                  supplierSnap.data().name ||
                  "Unknown Supplier"
                : "Unknown Supplier";

              // Return enriched chat data
              return {
                id: chatDoc.id,
                ...chatData,
                productName,
                supplierName,
                displayText: `${productName} - ${supplierName}`,
              };
            } catch (error) {
              console.error("Error processing chat document:", error);
              return null;
            }
          })
        );

        setChats(chatList.filter((chat) => chat !== null));
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    const fetchOrders = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("User not authenticated.");
          return;
        }

        const ordersRef = collection(db, "orders");
        const ordersQuery = query(ordersRef, where("buyerId", "==", user.uid));
        const ordersSnapshot = await getDocs(ordersQuery);

        const orderList = ordersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setOrders(orderList);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchBuyerData();
    fetchChats();
    fetchOrders();
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='container py-5'>
      <div className='row mb-4'>
        <div className='col'>
          <h2 className='text-success font-weight-bold'>
            Welcome, {buyerData?.name || "Buyer"}!
          </h2>
          <p className='text-muted'>
            Here’s a quick overview of your buyer account and recent activities.
          </p>
        </div>
      </div>

      <div className='row'>
        {/* Messages Section */}
        <div className='col-md-6 mb-4'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h5 className='card-title text-success font-weight-bold'>
                Messages
              </h5>
              <p className='card-text'>
                Communicate with suppliers and check updates on your purchases.
              </p>
              {chats.length > 0 ? (
                <ul className='list-group list-group-flush'>
                  {chats.map((chat) => (
                    <li className='list-group-item' key={chat.id}>
                      <a
                        href={`/chat/${chat.id}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-success'
                      >
                        {chat.displayText}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className='text-muted'>No messages yet.</p>
              )}
            </div>
          </div>
        </div>

        {/* Orders Section */}
        <div className='col-md-6 mb-4'>
          <div className='card shadow-sm'>
            <div className='card-body'>
              <h5 className='card-title text-success font-weight-bold'>
                Order List
              </h5>
              <p className='card-text'>View the status of your orders below.</p>
              {orders.length > 0 ? (
                <ul className='list-group list-group-flush'>
                  {orders.map((order) => (
                    <li className='list-group-item' key={order.id}>
                      <strong>Order ID:</strong> {order.id} <br />
                      <strong>Status:</strong> {order.status} <br />
                      <strong>Total:</strong> ${order.totalAmount.toFixed(2)}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className='text-muted'>No orders found.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerDashboard;
