import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, ListGroup } from "react-bootstrap";
import { db, auth } from "../../firebase/config";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";

const SupplierDashboard = () => {
  const [supplierData, setSupplierData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chats, setChats] = useState([]);

  const navigate = useNavigate();

  // Logout functionality
  const handleLogout = async () => {
    try {
      // Firebase sign-out
      await signOut(auth);

      // Clear localStorage
      localStorage.removeItem("userId");
      localStorage.removeItem("userRole");

      // Redirect to login
      navigate("/user-login");
    } catch (error) {
      console.error("Error logging out:", error);
      alert("Failed to log out. Please try again.");
    }
  };

  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setSupplierData(docSnap.data());
          } else {
            console.log("No such document!");
          }
        }
      } catch (error) {
        console.error("Error fetching supplier data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchChats = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("User not authenticated.");
          return;
        }

        const chatsRef = collection(db, "chats");
        const chatsQuery = query(chatsRef, where("supplierId", "==", user.uid));
        const chatsSnapshot = await getDocs(chatsQuery);

        if (chatsSnapshot.empty) {
          setChats([]); // No chats found
          return;
        }

        const chatList = await Promise.all(
          chatsSnapshot.docs.map(async (chatDoc) => {
            const chatData = chatDoc.data();

            try {
              // Fetch product details
              const productRef = doc(db, "products", chatData.productId);
              const productSnap = await getDoc(productRef);
              const productName = productSnap.exists()
                ? productSnap.data().productName
                : "Unnamed Product";

              // Fetch buyer details
              const buyerRef = doc(db, "users", chatData.buyerId);
              const buyerSnap = await getDoc(buyerRef);
              const buyerName = buyerSnap.exists()
                ? buyerSnap.data().name
                : "Unknown Buyer";

              // Return enriched chat data
              return {
                id: chatDoc.id, // Use chat document ID for navigation
                ...chatData,
                productName,
                buyerName,
                displayText: `${productName} - ${buyerName}`,
              };
            } catch (error) {
              console.error("Error processing chat document:", error);
              return null;
            }
          })
        );

        setChats(chatList.filter((chat) => chat !== null));
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchSupplierData();
    fetchChats();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Container
        fluid
        className='d-flex flex-column align-items-center justify-content-center py-5'
        style={{ minHeight: "100vh", backgroundColor: "#f7f7f7" }}
      >
        <Row className='mb-4'>
          <Col>
            <h2
              style={{
                color: "#2d6a4f",
                fontWeight: "bold",
                textAlign: "center", // Center text
              }}
            >
              Welcome, {supplierData?.companyName || "Supplier"}!
            </h2>
            <p className='text-muted'>
              Here’s a quick overview of your supplier account and recent
              activities.
            </p>
          </Col>
        </Row>

        <Row className='w-100' style={{ maxWidth: "1200px" }}>
          {/* Manage Profiles */}
          <Col md={6} className='mb-4'>
            <Card className='shadow-sm'>
              <Card.Body>
                <Card.Title style={{ color: "#2d6a4f", fontWeight: "bold" }}>
                  Manage Profiles
                </Card.Title>
                <Card.Text>
                  Access and update profiles associated with your account.
                </Card.Text>
                <div className='d-flex'>
                  <Button
                    variant='success'
                    style={{
                      backgroundColor: "#2d6a4f",
                      borderColor: "#2d6a4f",
                      marginRight: "10px",
                    }}
                    onClick={() => navigate("/manage-profiles")}
                  >
                    Manage Profiles
                  </Button>
                  <Button
                    variant='success'
                    style={{
                      backgroundColor: "#2d6a4f",
                      borderColor: "#2d6a4f",
                      marginRight: "10px",
                    }}
                    onClick={() => navigate("/manage-profiles")}
                  >
                    Manage Employee/s
                  </Button>
                  <Button
                    variant='secondary'
                    style={{
                      backgroundColor: "#6c757d",
                      borderColor: "#6c757d",
                    }}
                  >
                    Add Terms and Conditions
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Messages / Notifications */}
          <Col md={6} className='mb-4'>
            <Card className='shadow-sm'>
              <Card.Body>
                <Card.Title style={{ color: "#2d6a4f", fontWeight: "bold" }}>
                  Messages
                </Card.Title>
                <Card.Text>
                  Communicate with buyers and check updates from your customers.
                </Card.Text>
                {chats.length > 0 ? (
                  <ListGroup variant='flush'>
                    {chats.map((chat) => (
                      <ListGroup.Item key={chat.id}>
                        <Link
                          to={`/chat/${chat.id}`}
                          target='_blank'
                          style={{ textDecoration: "none", color: "#2d6a4f" }}
                        >
                          {chat.displayText}
                        </Link>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                ) : (
                  <p>No messages yet.</p>
                )}
              </Card.Body>
            </Card>
          </Col>

          {/* Manage Listings with Add Product button */}
          <Col md={6} className='mb-4'>
            <Card className='shadow-sm'>
              <Card.Body>
                <Card.Title style={{ color: "#2d6a4f", fontWeight: "bold" }}>
                  Manage Listings
                </Card.Title>
                <Card.Text>
                  Manage your products, update stock, and add new listings.
                </Card.Text>
                <div className='d-flex'>
                  <Button
                    variant='success'
                    style={{
                      backgroundColor: "#2d6a4f",
                      borderColor: "#2d6a4f",
                      marginRight: "10px",
                    }}
                    onClick={() => navigate("/edit-products")}
                  >
                    Edit Products
                  </Button>
                  <Button
                    variant='success'
                    style={{
                      backgroundColor: "#2d6a4f",
                      borderColor: "#2d6a4f",
                    }}
                    onClick={() => navigate("/add-product")}
                  >
                    Add Products
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Manage Orders */}
          <Col md={6} className='mb-4'>
            <Card className='shadow-sm'>
              <Card.Body>
                <Card.Title style={{ color: "#2d6a4f", fontWeight: "bold" }}>
                  Manage Orders
                </Card.Title>
                <Card.Text>
                  View and update the status of your customer orders.
                </Card.Text>
                <Button
                  variant='success'
                  style={{ backgroundColor: "#2d6a4f", borderColor: "#2d6a4f" }}
                  onClick={() => navigate("/manage-orders")}
                >
                  Manage Orders
                </Button>
              </Card.Body>
            </Card>
          </Col>

          {/* Account Settings */}
          <Col md={6} className='mb-4'>
            <Card className='shadow-sm'>
              <Card.Body>
                <Card.Title style={{ color: "#2d6a4f", fontWeight: "bold" }}>
                  Account Settings
                </Card.Title>
                <Card.Text>
                  Manage your profile, update contact details, and more.
                </Card.Text>
                <Button
                  variant='success'
                  style={{ backgroundColor: "#2d6a4f", borderColor: "#2d6a4f" }}
                  onClick={() => navigate("/account-settings")}
                >
                  Manage Account
                </Button>
              </Card.Body>
            </Card>
          </Col>

          {/* Support */}
          <Col md={6} className='mb-4'>
            <Card className='shadow-sm'>
              <Card.Body>
                <Card.Title style={{ color: "#2d6a4f", fontWeight: "bold" }}>
                  Help & Support
                </Card.Title>
                <Card.Text>
                  Get help with any issues or reach out to our support team.
                </Card.Text>
                <Button
                  variant='success'
                  style={{ backgroundColor: "#2d6a4f", borderColor: "#2d6a4f" }}
                >
                  Contact Support
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Button
          variant='danger'
          style={{ marginTop: "20px" }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Container>
    </>
  );
};

export default SupplierDashboard;
